import React, { useEffect,useState } from "react";
import { useLocation,useHistory } from "react-router-dom";
import {Helmet} from "react-helmet";
import CompanyBanner from "../../component/CompanyBanner";
import CitySearch from "../../component/CitySearch";
import CompareAndSave from "../../component/CompareAndSave";
import RecentComparisons from "../../component/RecentComparisons";
import CompanyFeature from "../../component/CompanyFeature";
import RelatedArticles from "../../component/RelatedArticles";
import MonthlyPrice from "../../component/MonthlyPrice";
import Adds from "../../component/Adds";
import { getVendor } from "../../utility/apiService";
import { isEmpty } from "lodash";

const Company = () => {
  //Api
  const [companyItem, setCompanyItem] = useState([]);
  const [categoryImgPath, setCategoryImgPath] = useState([]);
  const [isLoding, setIsloading] = useState(false);
  const location = useLocation() 
  const history = useHistory()
  const [userStatus, setUserStatus] = useState(true);
    useEffect(() => {
        const userInfoFromLS = localStorage.getItem("user_info");
        const userInfo = JSON.parse(userInfoFromLS);
        if (userInfo) {
          setUserStatus(false)
        }
      }, [])
  useEffect(() => {
    setIsloading(true) 
    let slug = location && location.pathname && location.pathname.split('/')[2]
    getVendor(slug)
    .then((res) => {
      if(!!res.data){
        setIsloading(false) 
      }
      if(isEmpty(res.data.data)){
        history.push('/page-not-found')
      }
      setCompanyItem(res.data.data);
      setCategoryImgPath(res.data.metadata.imageUrl)
    })
    .catch((error) => {
      console.error(error);
      setIsloading(false) 
    })
    window.scrollTo(0,0);
  }, [location])
  const {banner_title,banner_image,banner_description,meta_title,meta_description,meta_keywords,description1,blogs,id,ads,amount,caption,website_url} = companyItem;
  return (
    <>
      {isLoding &&
        <div className="loader" style={{backgroundImage: `url('../images/loader.png')`}}></div>
      }
      <Helmet>
        <title>{meta_title && meta_title}</title>
        <meta name="description" content={meta_description && meta_description} />
        <meta name="keywords" content={meta_keywords && meta_keywords} />
      </Helmet>
      <CompanyBanner banner_title={banner_title} banner_image={banner_image} banner_description={banner_description} categoryImgPath={categoryImgPath}/>
      {/* <section className="city-outer white-bg">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
            <CitySearch />
            </div>
          </div>
        </div>
      </section> */}
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-8 col-md-8">
            <CompanyFeature description1={description1}/>
            </div>
            <div className="col-lg-3 col-md-4">
            {(!isEmpty(website_url) || !isEmpty(blogs && blogs.data) || !isEmpty(ads)) &&
              <div className="right-side-bar">
                <MonthlyPrice userStatus={userStatus} amount={amount} caption={caption} website_url={website_url}/>
                <RelatedArticles blogs={blogs} id={id}/>
                <Adds ads={ads}/>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
      <RecentComparisons />
      <CompareAndSave/>
    </>
  );
};

export default Company;
