import React, {} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import ResultNotFound from "../../component/ResultNotFound";
import './style.css'
const BlogList = ({blogItem,categoryImgPath}) => {
    return(
      <>
    {blogItem && blogItem.length > 0 ? (
      <ul className="blog-list">
         {blogItem.map((item,idx) => (
        <li key={idx}>
            <Link to={'/blog/'+item.slug}>
                <div className="img">
                  <figure className="blog-list-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                    <LazyLoadImage alt={item.title} effect="blur" src={`${categoryImgPath}${item.image_name}`} />
                  </figure> 
                </div>
                <div className="text">
                    <h4>{item.title}</h4>
                    <p>{item.short_description}</p>
                </div>
            </Link>
        </li>
        ))}
      </ul>
      ) : (
        <ResultNotFound />
      )}
      </>
    )
}

export default BlogList;