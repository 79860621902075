import React from 'react';
import './style.css';

const SiteMapBanner = () => {
  return (
    <div className="banner">
      <div className="container">
          <div className="row banner-inner">
              <div className="offset-lg-1 col-lg-5 col-md-6 banner-left pb-30">
                <h1>Sitemap</h1>
                <p>Locate what you need on the website through the site map!</p>
              </div>
              <div className="col-lg-4 col-md-5 offset-lg-2 offset-md-1 banner-right"></div>
          </div>
      </div>
    </div>
  );
};

export default SiteMapBanner;
