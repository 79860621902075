import React, { useEffect,useState } from "react";
import {Helmet} from "react-helmet";
import HomeBanner from "../../component/HomeBanner";
//hide city search
import CitySearch from "../../component/CitySearch";
import CompareAndSave from "../../component/CompareAndSave";
import TccFeature from "../../component/TccFeature";
import BestDeals from "../../component/BestDeals";
import RecentComparisons from "../../component/RecentComparisons";
import TrustedAndRenowned from "../../component/TrustedAndRenowned";
import Testimonials from "../../component/Testimonials";
import GrapNewOffer from "../../component/GrapNewOffer";
import { getHomeData } from "../../utility/apiService";
import useLoader from "../../hook/useLoader";
const Home = () => {
  const [homeItem, setHomeItem] = useState([]);
  const [categoryImgPath, setCategoryImgPath] = useState([]);
  //const [isLoding, setIsloading] = useState(false);
  const [loader, showLoader, hideLoader] = useLoader();
  useEffect(() => {
    //setIsloading(true) 
    showLoader();
    getHomeData()
    .then((res) => {
      if(!!res.data){
        hideLoader();
        //setIsloading(false) 
      }
      setHomeItem(res.data.data[0]);
      setCategoryImgPath(res.data.metadata.imageUrl)
    })
    .catch((error) => {
      console.error(error);
      hideLoader();
      //setIsloading(false) 
    })
    window.scrollTo(0,0);
  }, [])
  useEffect(() => {
    document.body.className = 'd-top-strip';
    return () => {document.body.className = ''}
  }, [])
  const {banner_title,banner_image,banner_description,meta_title,meta_description,meta_keywords,button_url,button_label} = homeItem;
  //recZipCode
  const [zipCodeP, setZipCodeP] = useState('');
  const [cityP, setCityP] = useState('');
  //zip code 
  const recZipCode = (zipCodeP,cityP) =>{
    setZipCodeP(zipCodeP)
    setCityP(cityP)
  } 
  return (
    <>
      {/* {isLoding &&
        <div className="loader" style={{backgroundImage: `url('../images/loader.png')`}}></div>
      } */}
      {loader}
      <Helmet>
        <title>{meta_title && meta_title}</title>
        <meta name="description" content={meta_description && meta_description}/>
        <meta name="keywords" content={meta_keywords && meta_keywords} />
      </Helmet>
      <HomeBanner zipCodeP={zipCodeP} cityP={cityP} banner_title={banner_title} banner_image={banner_image} banner_description={banner_description} categoryImgPath={categoryImgPath} button_url={button_url} button_label={button_label}/>
      {/* Hiding city input bar */}
      <section className="city-outer">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
            <CitySearch recZipCode={recZipCode}/>
            </div>
          </div>
        </div>
      </section>
      <CompareAndSave/>
      <TccFeature />
      <RecentComparisons />
      <TrustedAndRenowned/>
      <Testimonials/>
      <GrapNewOffer />
      <BestDeals />
    </>
  );
};

export default Home;
