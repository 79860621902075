import React from "react";
import "./style.css";
const tccFeature = () => {
  return (
    <section className="tcc-feature">
      <div className="container">
        <div className="row">
          <div className="offset-lg-1 col-lg-3 col-md-4 tcc-feature-left">
            <img src="../images/tcc-feature.png" alt=""/>
          </div>
          <div className="offset-lg-1 col-lg-6 col-md-8 tcc-feature-right">
            <ul className="tcc-feature-list">
              <li>
                <div className="img">
                <img src="../images/clock.svg" alt="5 minutes to save"/>
                </div>
                <div className="tcc-feature-text">
                  <h4>5 minutes to save</h4>
                  <p>Choose a service you would like to see comparisons for</p>
                </div>
              </li>
              <li>
                <div className="img">
                <img src="../images/search.svg" alt="We’ll search for savings"/>
                </div>
                <div className="tcc-feature-text">
                  <h4>We’ll search for savings</h4>
                  <p>We’ll show you how much you could save by switching</p>
                </div>
              </li>
              <li>
                <div className="img">
                <img src="../images/protect.svg" alt="Data Protected"/>
                </div>
                <div className="tcc-feature-text">
                  <h4>Data Protected</h4>
                  <p>We make sure your data is protected and you’re safe</p>
                </div>
              </li>
              <li>
                <div className="img">
                <img src="../images/save.svg" alt="Choose, sign up, sit back"/>
                </div>
                <div className="tcc-feature-text">
                  <h4>Choose, sign up, sit back</h4>
                  <p>Enter a few details to sign up  and you are done</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default tccFeature;
