import React from 'react';
import FacebookLogin from 'react-facebook-login';

const Facebook = ({responseFacebook}) => {
  return (
      <FacebookLogin
        appId="529558701671137"
        autoLoad={false}
        fields="name,email,picture"
        scope="public_profile, email"
        callback={responseFacebook}
      />
  )
};

export default Facebook;