import React, {} from 'react';
import ReactHtmlParser from 'react-html-parser';
import './style.css'

const companyFeature = ({description1}) => {
    return(
      <div className="company-feature">
          {ReactHtmlParser(description1)}
      </div>
    )
}

export default companyFeature;