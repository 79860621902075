import React, {} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import './style.css'

const servicesList = ({subcategory,categoryImgPath,slug}) => {
    return(
        <ul className="services-list">
            {subcategory && subcategory.map((i,idx) => (
            <li key={idx}>
                <div className="services-list-left">
                    <div className="img-icon">
                        <img src={`${categoryImgPath}${i.icon_name}`} alt={i.name}/>
                    </div>
                    <h4><Link to={'/'+slug+'/'+i.slug}>{i.name}</Link></h4>
                    <p>{i.short_description2}</p>
                </div>
                <div className="img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                    <Link to={'/'+slug+'/'+i.slug}>
                        <LazyLoadImage alt={i.name} effect="blur" src={`${categoryImgPath}${i.banner_image}?t=${i.added_at}`} />
                    </Link>
                </div>
            </li>
            ))}
        </ul>
    )
}

export default servicesList;