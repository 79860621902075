import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import {checkLogin} from "../../Action/actions";
import './style.css'

const ComparisonsList = ({vendors,resultImgPath}) => {
    //Sign IN
    const dispatch = useDispatch();
    const OpenSignInPopup = () => {
        dispatch(checkLogin(true))
    }
    const [userStatus, setUserStatus] = useState(true);
    useEffect(() => {
        const userInfoFromLS = localStorage.getItem("user_info");
        const userInfo = JSON.parse(userInfoFromLS);
        if (userInfo) {
          setUserStatus(false)
        }
      }, [])
    return(
        <ul className="comparisons-list">
            {vendors && vendors.map((item,idx) => (
            <li key={idx}>
                <div className="img">
                    <figure className="cl-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                        <LazyLoadImage alt={item.name} effect="blur" src={`${resultImgPath}${item.image_name}`} />
                    </figure>
                </div>
                <h4>{item.name}</h4>
                <p>{item.short_description}</p>
                <div className="top-company-box">
                    <div className="company-img">
                    <Link to={'/company/'+item.slug}>
                        <img src={`${resultImgPath}${item.logo_name}`} alt={item.name}/>
                    </Link>
                    </div>
                    <p><strong>{item.amount}</strong> <span>{item.caption}</span></p>
                    <div className="text-center">
                        <a href={item.website_url} className={`button`} target="_blank" rel="noreferrer">Get Deal</a>
                    </div>
                </div>  
            </li>
            ))}
        </ul>
    )
}

export default ComparisonsList;