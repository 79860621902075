import React, {} from 'react';
import './style.css';

const PrivacyContent = () => {
  return (
    <div className="section">
      <div className="container">
        <div className="row">
          <div className="offset-lg-1 col-lg-10 col-md-12">
            <div className="common-content">
              <h4>Our promise to you</h4>
              <p>Protecting your private information is our priority. This Statement of Privacy applies to <a href="/">https://thecomparison.company</a> and The Comparison Company Inc and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to The Comparison Company Inc include <a href="/">https://thecomparison.company</a> and TCC. The TCC website is a Price Comparison site. By using the TCC website, you consent to the data practices described in this statement.</p>

              <h3>Collection of your Personal Information</h3>
              <p>TCC may collect anonymous demographic information, which is not unique to you, such as your:</p>
              <ul>
                <li>Age</li>
                <li>Gender</li>
                <li>Household Income </li>
                </ul>
                <p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services available on the Site. These may include: (a) registering for an account on our Site; (b) entering a sweepstake or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services on our Site. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</p>
                <h3>Sharing Information with Third Parties</h3>
                <p>TCC does not sell, rent or lease its customer lists to third parties. &nbsp;</p>
                <p>TCC may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is transferred to a third party. TCC may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to TCC, and they are required to maintain the confidentiality of your information. &nbsp;</p>
                <p>TCC may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on TCC or the site; (b) protect and defend the rights or property of TCC; and/or (c) act under exigent circumstances to protect the personal safety of users of TCC, or the public. &nbsp;</p>
                <h3>Opt-Out of Disclosure of Personal Information to Third Parties </h3>
                <p>In connection with any personal information, we may disclose to a third party for a business purpose, you have the right to know:</p>
                <ul>
                  <li>The categories of personal information that we disclosed about you for a business purpose.</li>
                </ul>
                <p>You have the right under the California Consumer Privacy Act of 2018 (CCPA) and certain other privacy and data protection laws, as applicable, to opt-out of the disclosure of your personal information. If you exercise your right to opt-out of the disclosure of your personal information, we will refrain from disclosing your personal information, unless you subsequently provide express authorization for the disclosure of your personal information. To opt-out of the disclosure of your personal information, visit <a href="/app/profile">this</a> Web page.</p>
                <h3>Tracking User Behavior </h3>
                <p>TCC may keep track of the websites and pages our users visit within TCC, in order to determine what TCC services are the most popular. This data is used to deliver customized content and advertising within TCC to customers whose behavior indicates that they are interested in a particular subject area. &nbsp;</p>
                <h3>Use of Cookies</h3>
                <p>The TCC website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>
                <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize TCC pages, or register with TCC site or services, a cookie helps TCC to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same TCC website, the information you previously provided can be retrieved, so you can easily use the TCC features that you customized.</p>
                <p>You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the TCC services or websites you visit.</p>
                <h3>Links</h3>
                <p>This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>
                <h3>Security of your Personal Information</h3>
                <p>TCC secures your personal information from unauthorized access, use, or disclosure. TCC uses the following methods for this purpose:</p>
                <ul>
                  <li>SSL Protocol</li>
                </ul>
                <p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>
                <p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.</p>
                <h3>Right to Deletion</h3>
                <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:</p>
                <ul>
                  <li>Delete your personal information from our records; and </li>
                  <li>Direct any service providers to delete your personal information from their records.</li>
                </ul>
                <p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:</p>
                <ul>
                  <li>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us; </li>
                  <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;</li>
                  <li>Debug to identify and repair errors that impair existing intended functionality;</li>
                  <li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;</li>
                  <li>Comply with the California Electronic Communications Privacy Act;</li>
                  <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;</li>
                  <li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us; </li>
                  <li>Comply with an existing legal obligation; or</li>
                  <li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</li>
                </ul>
                <h3>Children Under Thirteen </h3>
                <p>TCC does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.</p>
                <h3>Disconnecting your TCC Account from Third Party Websites </h3>
                <p>You will be able to connect your TCC account to third party accounts. BY CONNECTING YOUR TCC ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE. You may disconnect your account from a third party account at any time. Users may learn how to disconnect their accounts from third-party websites by visiting their "My Account" page. Users may also contact us via email or telephone.</p>
                <h3>Opt-Out &amp; Unsubscribe from Third Party Communications </h3>
                <p>We respect your privacy and give you an opportunity to opt-out of receiving announcements of certain information. Users may opt-out of receiving any or all communications from third-party partners of TCC by contacting us here:</p>
                <ul>
                  <li><a href="/app/profil">In your account profile</a></li>
                  <li>Via email at 
                    <div><a target="_blank" rel="noreferrer" href="mailto:support@thecomparison.company">support@thecomparison.company</a></div>
                  </li>
                  <li>Via phone at <div>
                    <a target="_blank" rel="noreferrer" href="tel:214-810-5972">214-810-5972</a></div>
                  </li>
                </ul>
                <h3>E-mail Communications </h3>
                <p>From time to time, TCC may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication. In order to improve our Services, we may receive a notification when you open an email from TCC or click on a link therein.</p>
                <p>If you would like to stop receiving marketing or promotional communications via email from TCC, you may opt out of such communications by Clicking the unsubscribe link within the email.</p>
                <h3>External Data Storage Sites</h3>
                <p>We may store your data on servers provided by third party hosting vendors with whom we have contracted.</p>
                <h3>Changes to this Statement</h3>
                <p>TCC reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our site, and/or by updating any privacy information on this page. Your continued use of the Site and/or Services available through this Site after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>
                <h3>Contact Us</h3>
                <h3>Privacy</h3>
                <p>Your use of <a href="/">https://thecomparison.company</a> is subject to TCC's Privacy Policy. Please review our <a aria-current="page" href="/privacy-policy">Privacy Policy</a>, which also governs the Site and informs users of our data collection practices.</p>  


              <h3>Contact Us</h3>
              <p>TCC welcomes your questions or comments regarding the Terms:  </p>
              <p className="mb-0">The Comparison Company Inc.</p>
              <p className="mb-0">14681 Midway Rd, Suite 200</p>
              <p className="mb-0">Addison</p>
              <p className="mb-0">Texas</p>
              <p>75001</p>
              <p className="mb-1">E-mail : <a target="_blank" rel="noreferrer" href="mailto:support@thecomparison.company">support@thecomparison.company</a></p>
              <p>Call : <a target="_blank" rel="noreferrer" href="tel:214-810-5972">214-810-5972</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyContent;
