import React, { useEffect,useState } from "react";
import {Helmet} from "react-helmet";
import { useLocation,useHistory } from "react-router-dom";
import CategoryBanner from "../../component/CategoryBanner";
import CitySearch from "../../component/CitySearch";
import CompareAndSave from "../../component/CompareAndSave";
import ServicesTopHead from "../../component/ServicesTopHead";
import ServicesList from "../../component/ServicesList";
import Adds from "../../component/Adds";
import RelatedArticles from "../../component/RelatedArticles";
import { getCategories } from "../../utility/apiService";
import { isEmpty } from "lodash";
const Category = () => {
  //Api
  const [categoryItem, setCategoryItem] = useState([]);
  const [categoryImgPath, setCategoryImgPath] = useState([]);
  const [isLoding, setIsloading] = useState(false);
  const location = useLocation() 
  const history = useHistory()
  useEffect(() => {
    setIsloading(true) 
    let slug = location && location.pathname && location.pathname.split('/')[1]
    getCategories(slug)
    .then((res) => {
      if(!!res.data){
        setIsloading(false) 
      }
      if(isEmpty(res.data.data)){
        history.push('/page-not-found')
      }
      setCategoryItem(res.data.data);
      setCategoryImgPath(res.data.metadata.imageUrl)
    })
    .catch((error) => {
      console.error(error);
      setIsloading(false) 
    })
    window.scrollTo(0,0);
  }, [location])
  const {title1,banner_title,banner_image,short_description2,short_description1,subcategory,meta_title,meta_description,meta_keywords,slug,ads,blogs,id} = categoryItem;
  return (
    <>
      {isLoding &&
        <div className="loader" style={{backgroundImage: `url('../images/loader.png')`}}></div>
      }
      <Helmet>
        <title>{meta_title && meta_title}</title>
        <meta name="description" content={meta_description && meta_description} />
        <meta name="keywords" content={meta_keywords && meta_keywords} />
      </Helmet>
      <CategoryBanner banner_title={banner_title} banner_image={banner_image} short_description2={short_description2} categoryImgPath={categoryImgPath}/>
      {/* <section className="city-outer white-bg">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
            <CitySearch />
            </div>
          </div>
        </div>
      </section> */}
      <ServicesTopHead title1={title1} short_description1={short_description1}/>
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-8 col-md-8">
              <ServicesList subcategory={subcategory} slug={slug} categoryImgPath={categoryImgPath}/>
            </div>
            <div className="col-lg-3 col-md-4">
            {(!isEmpty(ads) || !isEmpty(blogs && blogs.data)) &&
              <div className="right-side-bar">
                <Adds ads={ads}/>
                <RelatedArticles blogs={blogs} id={id}/>
              </div>}
            </div>
          </div>
        </div>
      </div>
      <CompareAndSave/>
    </>
  );
};

export default Category;
