import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { FaCamera } from 'react-icons/fa';
import { Modal } from 'react-responsive-modal';
import { postLogout,postChangePassword} from "../../utility/apiService";
import {toast } from 'react-toastify';
import useLoader from "../../hook/useLoader";
import 'react-responsive-modal/styles.css';
import './style.css'
const UserProfile = ({handleUserProfile,image,handleUpload}) => {
    const [loader,showLoader] = useLoader();
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => {
      setOpen(false)
      /*resetPass*/
      setErrorEmpty({
        oldPasswordError : false,
        newPasswordError : false,
        confirmNewPasswordError : false,
        validNewPasswordError: false
      });
      setInputVal({
        old_password:'',
        new_password:'',
        confirm_new_password:'',
      })
      setCheckValidation(false)
    };
    const history = useHistory();
    //Update Profile Img
    const meta_data = localStorage.getItem("meta_data");
    let imageUrl;
    let profileUrl = null;
    if(meta_data === "null" || meta_data == undefined) {
      profileUrl = '';
    }
    else {
      profileUrl = JSON.parse(meta_data);
      imageUrl = profileUrl.imageUrl;
    }
    /*Update*/
    const [userInfo, setUserInfo] = useState([]);
    useEffect(() => {
      const userInfoFromLS = localStorage.getItem("user_info");
      const info = JSON.parse(userInfoFromLS);
      if (info) {
        setUserInfo(info)
      }
      else{
        history.push('/');
      }
    },[history])

    const getAvtar = () => {
      let avtar = null;
      if(image) {
        avtar = image.preview
      }
      return avtar
    }

    const userLogOut = () => {
      if(userInfo) {
        /*FB Logout*/
        if(!!userInfo.accessToken || !!userInfo.sub){
          localStorage.removeItem('user_info');
          window.location.reload()
        }else{
          postLogout(userInfo.user_token, "12345")
          .then((res) => {
            if(res.data.code === 200) {
              localStorage.removeItem('user_info');
              window.location.reload()
            }
            }).catch((error) => {
            console.error(error);
          })
        }
      }
    }
  /*Reset*/
  /*handleResetPasswordSubmit*/
  const [inputVal, setInputVal] = useState({
    old_password:'',
    new_password:'',
    confirm_new_password:'',
  });
  const handleInput = (e) =>{
    setInputVal({
      ...inputVal,
      [e.target.name]:e.target.value
    })
  }
  const [isErrorEmpty, setErrorEmpty] = useState({
    oldPasswordError : false,
    newPasswordError : false,
    confirmNewPasswordError : false,
    validNewPasswordError: false
  });
  const [checkValidation, setCheckValidation] = useState(false)
  const handleResetPassSubmit=(e)=>{
    e.preventDefault()
    setErrorEmpty({
      oldPasswordError : false,
      newPasswordError : false,
      confirmNewPasswordError : false,
      validNewPasswordError: false
    });
    handleValidation()
    setCheckValidation(true)
  } 
  useEffect(() => {
    const payload = {
      old_password: inputVal.old_password,
      new_password: inputVal.new_password,
      device_id: "12345"
    }
    const userInfoFromLS = localStorage.getItem("user_info");
    const info = JSON.parse(userInfoFromLS);
    if(checkValidation && !isErrorEmpty.oldPasswordError && !isErrorEmpty.newPasswordError && !isErrorEmpty.confirmNewPasswordError && !isErrorEmpty.validNewPasswordError){
      postChangePassword(userInfo.user_token,payload)
      .then((res) => {
        if(res.data.code === 200) {
          toast.success(res.data.message)
          info.user_token = res.data.data.user_token
          localStorage.setItem("user_info", JSON.stringify(info));
          onCloseModal();
          showLoader()
          setTimeout(() => {
            window.location.reload()
          }, 5000);
        }
        if(res.data.code === 401) {
          toast.error(res.data.message)
        }
      })
      .catch((error) => {
        console.error(error);
      })
    }
    setCheckValidation(false)
 },[checkValidation])
  const handleValidation=()=>{
    if(inputVal.old_password ===''){  
      setErrorEmpty(prevError => {
        return { 
          ...prevError, 
          oldPasswordError: true
        }
      })
    }
    if(inputVal.new_password ===''){  
      setErrorEmpty(prevError => {
        return { 
          ...prevError, 
          newPasswordError: true
        }
      })
    }
    if(inputVal.confirm_new_password ===''){  
      setErrorEmpty(prevError => {
        return { 
          ...prevError, 
          confirmNewPasswordError: true
        }
      })
    }else if(inputVal.confirm_new_password!==inputVal.new_password){
      setErrorEmpty(prevError => {
        return { 
          ...prevError, 
          validNewPasswordError: true
        }
      })
    }
  }
  const getAvtarName = () => {
    let photo = null;
    if(userInfo.updated_at) {
      photo = `${userInfo.photo_name}?t=${userInfo.updated_at}`
    }
    else {
      photo = userInfo.photo_name
    }
    return photo
  }
    return(
        <>
        {loader}
        <div className="user-profile">
            <div className="user-img">
                {userInfo.photo_name && userInfo.is_social == "1" ? <img src={userInfo.photo_name} alt="user icon"/> :
                userInfo.photo_name ? (<img src={getAvtar() ? getAvtar() : imageUrl + getAvtarName()} alt="user icon"/>) : (getAvtar() ? <img src={getAvtar()} alt="user icon"/> :
                <span>{userInfo && userInfo.name && userInfo.name.substring(0, 1)}</span>)}
                {userInfo.photo_name && userInfo.is_social == "1" ? null: 
                <div className="edit-pic">
                  <FaCamera className="camra"/>
                  <input type="file" onChange={(e)=>handleUserProfile(e)} />
                </div>}
            </div>
            <p>{userInfo && userInfo.nickname ? userInfo.nickname : userInfo && userInfo.name}</p>
            <button onClick={handleUpload} className={"button green upload-button" + (image&& !image.preview ? " disabled" : '')}>Update Avtar</button>
            <ul className="sub-menu">
                <li><Link to='/profile'>Profile</Link></li>
                {userInfo.photo_name && userInfo.is_social == "1" ? null:
                <li><Link to='#'  onClick={onOpenModal}>Reset Password</Link></li>}
                <li><button className="button" onClick={userLogOut}>Log out</button></li>
            </ul>
        </div>
        {/*Reset Password*/}
        <Modal open={open} onClose={onCloseModal} center modalId="reset-password">
          <h3>Reset Password</h3>
          <form className="profile-form" onSubmit={(e)=>handleResetPassSubmit(e)}>
            <div className="form-group mb-3">
              <label>Old Password <span className="error-star">*</span></label>
              <input type="password" name="old_password" className="form-control" onChange={(e)=>handleInput(e)}/>
              {isErrorEmpty.oldPasswordError &&
              <p className="error">Please Enter Old Password</p>}
            </div>
            <div className="form-group mb-3">
              <label>New Password <span className="error-star">*</span></label>
              <input type="password" name="new_password" className="form-control" onChange={(e)=>handleInput(e)}/>
              {isErrorEmpty.newPasswordError &&
              <p className="error">Please Enter New Password</p>}
            </div>
            <div className="form-group mb-3">
              <label>Confirm New Password <span className="error-star">*</span></label>
              <input type="password" name="confirm_new_password" className="form-control" onChange={(e)=>handleInput(e)}/>
              {isErrorEmpty.confirmNewPasswordError &&
              <p className="error">Please Enter Confirm New Password</p>}
              {isErrorEmpty.validNewPasswordError &&
              <p className="error">Password doesn't match</p>}
            </div>
            <div className="btn-outer m-l-r-5 text-center">
            <button type="button" className="button cancel" onClick={onCloseModal}>Cancel</button>
            <button type="submit" className="button green">Update</button>
            </div>
          </form>
      </Modal>
     </>
    )
}

export default UserProfile;