import React, {useEffect} from 'react';
import { isEmpty } from 'lodash';
import './style.css'

const Adds = ({ads}) => {
    useEffect(() => {
        const pushAd = () => {
          try {
            const adsbygoogle = window.adsbygoogle
            adsbygoogle.push({})
          } catch (e) {
            console.error(e)
          }
        }
    
        let interval = setInterval(() => {
          if (window.adsbygoogle) {
            pushAd()
            clearInterval(interval)
          }
        }, 300)
        return () => {
          clearInterval(interval)
        }
      }, [])
    return(
        <>
        {!isEmpty(ads) && 
        <div className="adds">
            {ads && ads.is_external === '0' ?
            <a href={ads && ads.external_url} target="_blank" rel="noreferrer">
                <img src={`${ads && ads.adsUrl}${ads && ads.image_name}`} alt={ads && ads.title}/>
            </a> : <ins className="adsbygoogle"
                    style={{display:'block', width: "100%", height: "auto"}}
                    data-ad-client={ads && ads.ads_client}
                    data-ad-slot={ads && ads.ads_slot}
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
            }
        </div>}
        </>
    )
}
export default Adds;