import React, { useEffect } from "react";
import PartnerBanner from "../../component/PartnerBanner";
import CitySearch from "../../component/CitySearch";
import PartnerForm from "../../component/PartnerForm";

const Partner = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <>
      {/* <Helmet>
        <title>{meta_title && meta_title}</title>
        <meta name="description" content={meta_description && meta_description} />
        <meta name="keywords" content={meta_keywords && meta_keywords} />
      </Helmet> */}
      <PartnerBanner/>
      {/* <section className="city-outer white-bg">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
            <CitySearch />
            </div>
          </div>
        </div>
      </section> */}
      <PartnerForm/>
    </>
  );
};

export default Partner;
