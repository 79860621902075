import React, {} from 'react';
import ReactHtmlParser from 'react-html-parser';
import './style.css'

const comparisonTopHead = ({comparison_text}) => {
    return(
        <div className="section pb-0">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-1 col-lg-8 col-md-12">
                    <div className="head text-left">
                    {ReactHtmlParser(comparison_text)}
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
}

export default comparisonTopHead;