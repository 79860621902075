import React,{useEffect} from 'react';
import SiteMapBanner from '../../component/SiteMapBanner';
import CitySearch from "../../component/CitySearch";
import SiteMapContent from '../../component/SiteMapContent';
const SiteMap = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <>
      <SiteMapBanner />
      {/* <section className="city-outer white-bg">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
            <CitySearch />
            </div>
          </div>
        </div>
      </section> */}
      <SiteMapContent />
    </>
  );
};

export default SiteMap;
