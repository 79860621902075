import React from 'react';
import './style.css';

const AboutMission = () => {
  return (
    <div className="section about-mission">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-7 offset-lg-1">
            <h2>The Comparison Company Mission</h2>
            <p>The world is weird and difficult enough at the moment without worrying about utility bills.</p>
            <p>Nobody enjoys shopping for utilities, so we built a way to simplify the process. The goal is to save you time and money while protecting your privacy. It’s as simple as that.</p>
            <p>One Site, All Utilities.</p>
            <p>Other Sites Just Don’t Compare</p>
          </div>
          <div className="col-lg-3 col-md-5 offset-lg-1 about-mission-img">
          <img src="../images/about-mission.jpg" alt="About Mission"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMission;
