import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL + "/web/api";
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});
/*City Code*/
export const getCityCode = () => {
  return axios
  .get(`https://ipinfo.io/json?token=6e63eb5d66cea6`
)}
/*City Code*/
export const getZipCode = (pincode) => {
  return axios
  .get(`https://ziptasticapi.com/${pincode}`
)}
/*Social SignUp*/
export const getSocialToken = () => {
  return axiosInstance
  .get(`/getSocialToken`
)}
/*SignUp*/
export const postSignup = (signUpData) => {
  return axiosInstance
  .post(`/postSignup`,signUpData
)}
/*SignIN*/
export const postSignin = (signInData) => {
  return axiosInstance
  .post(`/postLogin`,signInData
)}
/*Reset Password*/
export const postChangePassword = (v1, v2) => {
  return axiosInstance
  .post(`/postChangePassword?token=${v1}`, v2
)}
/*Logout*/
export const postForgotPassword = (v1) => {
  return axiosInstance
  .post(`/postForgotPassword`,v1
)}
/*subscriber*/
export const subscriber = v1 => {
  return axiosInstance
  .post(`/postSubscriber`,v1
)}
/*postContact*/
export const postContact = v1 => {
  return axiosInstance
  .post(`/postContact`,v1
)}
/*postPartner*/
export const postPartner = v1 => {
  return axiosInstance
  .post(`/postPartner`,v1
)}

/*Forgot*/
export const postLogout = (logoutData, v1) => {
  return axiosInstance
  .post(`/postLogout?token=${logoutData}`, v1
)}
/*Profile*/
export const getProfile = (v1) => {
  return axiosInstance
  .get(`/getProfile?token=${v1}`
)}
/*postUpdateProfile*/
export const postUpdateProfile = (v1, v2) => {
  return axiosInstance
  .post(`/postUpdateProfile?token=${v1}`, v2,
  {headers: {'Content-Type': 'multipart/form-data'}}
)}
// Upload profile pic
export const postUpdateProfilePhoto = (v1, v2) => {
  return axiosInstance
  .post(`/postUpdateProfilePhoto?token=${v1}`, v2
)}
/*Header Footer*/
export const getCommonData = () => {
  return axiosInstance
  .get(`/getCommonData`,
  // {headers:{
  //   "TOKEN": "22CDDB7DBA0F9AAD99CD954F69A338C7"
  // }}
)}
/*Home*/
export const getHomeData = () => {
  return axiosInstance
  .get(`/getHomeData`
)}
/*Testimonial*/
export const getTestimonial = () => {
  return axiosInstance
  .get(`/getTestimonial`
)}
/*Trending Tabs*/
export const getTrending = () => {
  return axiosInstance
  .get(`/getTrending`
)}
/*Category & Result*/
export const getCategories = (slug,postalcode) => {
  return axiosInstance
  .get(`/getCategory?slug=${slug}&postalcode=${postalcode}`
)}
/*Compamy logo*/
export const getVendors = () => {
  return axiosInstance
  .get(`/getVendors`
)}
/*Company Page*/
export const getVendor = (slug) => {
  return axiosInstance
  .get(`/getVendor?slug=${slug}`
)}
/*Blog Page*/
export const getBlogs = (category_id,archive_id,searchFilter) => {
  return axiosInstance
  .get(`/getBlogs${category_id ? '?category_id='+category_id : ''}${archive_id ? '?archive='+archive_id : ''}${searchFilter ? '?q='+searchFilter : ''}`
)}
/*Blog Page*/
export const getBlogPageData = () => {
  return axiosInstance
  .get(`/getBlogPageData`
)}
/*Blog Detail Page*/
export const getBlog = (slug) => {
  return axiosInstance
  .get(`/getBlog?slug=${slug}`
)}
/*Blog Detail Page*/
export const getRecentDeals = () => {
  return axiosInstance
  .get(`/getRecentDeals`
)}