import React, { useState } from 'react';
import { subscriber } from "../../utility/apiService";
import {toast } from 'react-toastify';
import "./style.css";
import useLoader from "../../hook/useLoader";
const GrapNewOffer = () => {
  const [inputEmail, setInputEmail] = useState('');
  const [loader,showLoader, hideLoader] = useLoader();
  const handleInput = (e) =>{
    setInputEmail(e.target.value)
  }
  const [isError, setError] = useState(false);
  const [isErrorEmpty, setErrorEmpty] = useState(false);
  let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const handleSubmit=(e)=>{
    e.preventDefault()
    setError(false)
    setErrorEmpty(false)
    handleValidation()
    if(regEmail.test(inputEmail)){
      showLoader();
      subscriber({email: inputEmail})
        .then((res) => {
          if(res.data.code === 200) {
            toast.success(res.data.message)
            setInputEmail('')
          }
          if(res.data.code === 409) {
            toast.error(res.data.message)
          }
          hideLoader()
        })
        .catch((error) => {
            hideLoader()
            console.error(error)
        })
    }
  } 
  const handleValidation=()=>{
    if(inputEmail===''){  
      setErrorEmpty(true)
    }else{
      if(!regEmail.test(inputEmail)){
        setError(true);
      }  
    }
  }
  return (
    <>
    {loader}
    <section className="grap-new-offer">
      <div className="container">
        <div className="row">
          <div className="offset-lg-3 col-lg-6 col-md-12 grap-new-left">
            <div className="head">
              <h3 className="heading2">
                Be the first to grab new offers!
              </h3>
              <p>Leave us your email and we’ll send you the latest deals so you don’t miss out the best offers</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="input-outer">
                  <input type="text" value={inputEmail} className="form-control" onChange={(e)=>handleInput(e)}/>
                  <button type="submit" className="form-control submit">
                    Go
                  </button>
                  {isError &&
                    <p className="error">Please Enter Valid Email</p>
                  } 
                  {isErrorEmpty &&
                    <p className="error">Please Enter Email</p>
                  }
                  <p className="info">We value your privacy and won’t share your information with anyone</p>
              </div>
            </form>
          </div>
          <div className="col-lg-3 grap-new-right">
              <img src="../images/grap-offer.png" alt=""/>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default GrapNewOffer;
