import React, {} from 'react';
import './style.css'

const StayTune = () => {
    return(
        <div className="stay-tune">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <p>Stay in tune for 
                            <span><img src="../images/tcc-score.png" alt=""/></span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StayTune;