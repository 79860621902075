import React from 'react';
import { GoogleLogin } from 'react-google-login';

const Google = ({responseGoogle}) => {
  return (
    <GoogleLogin
      clientId="554050939354-kur8su6skn9j7lt51ksp7l0jb1h92jdq.apps.googleusercontent.com"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={'single_host_origin'}
    />
  )
};

export default Google;