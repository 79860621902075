import React from "react";
import {BrowserRouter as Router,Switch,Route} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import Header from "./component/Header";
import Footer from "./component/Footer";
import Home from "./Containers/Home";
import Category from "./Containers/Category";
import Result from "./Containers/Result";
import Company from "./Containers/Company";
import Profile from "./Containers/Profile";
import PageNotFound from "./Containers/PageNotFound";
import Blog from "./Containers/Blog";
import BlogDetail from "./Containers/BlogDetail";
import TeramAndCondition from "./Containers/TeramAndCondition";
import PrivacyPolicy from "./Containers/PrivacyPolicy";
import ContactUs from "./Containers/ContactUs";
import PartnerAndSupplier from "./Containers/PartnerAndSupplier";
import SiteMap from "./Containers/SiteMap";
import AboutUs from "./Containers/AboutUs";
import TopStrip from "./component/TopStrip";
const Routes = () => {
  return (
    <Router>
      <ToastContainer position="top-center"/>
      <div className="header-outer"> 
        <TopStrip />
        <Header />
      </div>
      <main>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/profile" exact component={Profile}/>
          <Route path="/blog" exact component={Blog} />
          <Route path="/term-condition" exact component={TeramAndCondition}/>
          <Route path="/privacy-policy" exact component={PrivacyPolicy}/>
          <Route path="/contact-us" exact component={ContactUs}/>
          <Route path="/partners-contact-us" exact component={PartnerAndSupplier}/>
          <Route path="/about-us" exact component={AboutUs}/>
          <Route path="/sitemap" exact component={SiteMap}/>
          <Route path="/page-not-found" exact component={PageNotFound} />
          <Route path="/blog/:blogDetail" exact component={BlogDetail} />
          <Route path="/company/:companylogo" exact component={Company} /> 
          <Route path="/:category" exact component={Category}/>
          <Route path="/:category/:result" exact component={Result}/>
        </Switch>
      </main>
      <Footer />
    </Router>
  );
};

export default Routes;
