import React, {} from 'react';
import { Link } from 'react-router-dom';
import './style.css'

const BlogArchive = ({archiveFilter,archive,blogShowAll}) => {
    return(
        <>
        {archive && archive.length > 0 &&
        <div className="categories blog-archive">
            <h3>Archives</h3>
            <ul className="category-list">
              {archive && archive.map((item,idx) => (
                  <li key={idx}><Link to={{pathname:'/blog', state:{archive_id:item.id}}} onClick={()=>archiveFilter(item.id)}>{item.name}</Link></li>
              ))}
            </ul>   
            <Link to='/blog' onClick={blogShowAll} className="green-link">Show All</Link> 
        </div>}
        </>
    )
}

export default BlogArchive;