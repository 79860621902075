import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getVendors } from "../../utility/apiService";
import "./style.css";
const TrustedAndRenowned = () => {
    //Api
    const [logo, setLogo] = useState([]);
    const [categoryImgPath, setCategoryImgPath] = useState([]);
    useEffect(() => {
      getVendors()
      .then((res) => {
        setLogo(res.data.data)
        setCategoryImgPath(res.data.metadata.imageUrl)
      })
      .catch((error) => {
        console.error(error);
      })
    }, [])
  return (
    <section className="renowned-outer">
      <div className="container">
        <div className="row">
          <div className="offset-lg-2 col-lg-8 col-md-12">
            <h3 className="heading2">
              Trusted and renowned providers under the same roof 
            </h3>
            <ul className="renowned-list">
            {logo && logo.map((item,idx) => (
              item.ispopular === '1' &&
              <li key={idx}>
                <Link to={'/company/'+item.slug}>
                  <img src={`${categoryImgPath}${item.logo_name}`} alt={item.name}/>
                </Link>
              </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustedAndRenowned;
