import React, { useEffect,useState } from "react";
import EditProfile from "../../component/EditProfile";
import StayTune from "../../component/StayTune";
import RecentComparisons from "../../component/RecentComparisons";
import { Modal } from 'react-responsive-modal';
import {getProfile,postUpdateProfile, postUpdateProfilePhoto} from "../../utility/apiService";
import 'react-responsive-modal/styles.css';
import {toast} from 'react-toastify';
import useLoader from "../../hook/useLoader";
import './style.css'

const Profile = () => {
  const [loader,showLoader,hideLoader] = useLoader();
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    setOpen(false);
    setErrorEmpty(false)
  }
  //Api get profile 
  const [profileItem, setProfileItem] = useState([]);
  const [isLoding, setIsloading] = useState(false);
  const userInfoFromLS = localStorage.getItem("user_info");
  const info = JSON.parse(userInfoFromLS);
  useEffect(() => {
    setIsloading(true) 
    if (info) {
      getProfile(info.user_token)
      .then((res) => {
        setIsloading(false) 
        setProfileItem(res.data.data)
      })
      .catch((error) => {
        console.error(error);
        setIsloading(false) 
      })
    }
  }, [])
  //Api post profile 
  const [inputVal, setInputVal] = useState({
    name: info && info.name ? info.name : '',
    address1: info && info.address1 ? info.address1 : '',
    address2: info && info.address2 ? info.address2 : '',
  });

  const [image, setImage] = useState({ preview: '', raw: '' })
  const handleUserProfile = (e) => {
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0]
    })
  }
  const handleUpload = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("profile_photo", image.raw);
    if(image.raw) {
      showLoader();
      postUpdateProfilePhoto(info.user_token,formData)
      .then((res) => {
        if(res.data.code === 200) {
          info.photo_name = res.data.data.photo_name
          info.updated_at = res.data.data.updated_at
          const getImageURL = localStorage.getItem("meta_data");
          if(getImageURL == null || getImageURL == undefined) {
            localStorage.setItem("meta_data", res.data.metadata);
          }
          else {
            localStorage.setItem("meta_data", JSON.stringify(res.data.metadata));
          }
          localStorage.setItem("user_info", JSON.stringify(info));
          toast.success(res.data.message)
          setTimeout(() => {
            hideLoader();
            window.location.reload(false);
          }, 5000);
        }
        if(res.data.code === 401) {
          toast.error(res.data.message)
        }
      }).catch((error) => {
        console.error(error);
      })
    }
  }
  const handleInput = (e,key) =>{
    if(key ==='name'){
      setInputVal({
      ...inputVal,
      name: e.target.value,
      })
    }
    if(key ==='address1'){
      setInputVal({
        ...inputVal,
        address1: e.target.value,
      })
    }
    if(key ==='address2'){
      setInputVal({
        ...inputVal,
        address2: e.target.value,
      })
    }
  }
  const [isErrorEmpty, setErrorEmpty] = useState(false);
  const handleSubmit=(e)=>{
    e.preventDefault()
    setErrorEmpty(false)
    handleValidation()
    const userInfoFromLS = localStorage.getItem("user_info");
    const info = JSON.parse(userInfoFromLS);
    if(inputVal.name!=='' && info){
      const formData = new FormData();
      formData.append("name", inputVal.name);
      formData.append("address1", inputVal.address1);
      formData.append("address2", inputVal.address2);
      postUpdateProfile(info.user_token,formData)
      .then((res) => {
        if(res.data.code === 200) {
          info.name = res.data.data.name
          info.address1 = res.data.data.address1
          info.address2 = res.data.data.address2
          localStorage.setItem("user_info", JSON.stringify(info));
          showLoader()
          toast.success(res.data.message)
            setTimeout(() => {
              window.location.reload(false);
            }, 5000);
        }
        if(res.data.code === 401) {
          toast.error(res.data.message)
        }
      }).catch((error) => {
        console.error(error);
      })
    }
  } 
  const handleValidation=()=>{
    if(inputVal.name===''){  
      setErrorEmpty(true)
    }
  }
  return (
    <>
    {loader}
      {isLoding &&
        <div className="loader" style={{backgroundImage: `url('../images/loader.png')`}}></div>
      }
      {/* <ToastContainer position="top-center"/> */}
      {/* <Helmet>
        <title>{meta_title && meta_title}</title>
        <meta name="description" content={meta_description && meta_description} />
        <meta name="keywords" content={meta_keywords && meta_keywords} />
      </Helmet> */}
      <EditProfile onOpenModal={onOpenModal}/>
      <StayTune />
      <RecentComparisons />
      <Modal open={open} onClose={onCloseModal} center modalId="profile-popup">
          <h3>Edit Profile</h3>
          <div className="avtar-section">
            <EditProfile handleUserProfile={handleUserProfile} image={image} handleUpload={handleUpload}/>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="profile-form">
              {profileItem && profileItem.photo_name && profileItem.is_social == "1" ? 
              <div className="form-group mb-3">
                <label>Full Name <span className="error-star">*</span></label>
                <input type="text" disabled="disabled" className="form-control" defaultValue={profileItem && profileItem.name} onChange={(e)=>handleInput(e,'name')}/>
              </div> :
              <div className="form-group mb-3">
                <label>Full Name <span className="error-star">*</span></label>
                <input type="text" className="form-control" defaultValue={profileItem && profileItem.name} onChange={(e)=>handleInput(e,'name')}/>
                {isErrorEmpty &&
                  <p className="error">Please Enter Name</p>}
              </div>}
              <div className="form-group mb-3">
                <label>Address1</label>
                <input type="text" className="form-control" defaultValue={profileItem && profileItem.address1} onChange={(e)=>handleInput(e,'address1')}/>
              </div>
              <div className="form-group mb-3">
                <label>Address2</label>
                <input type="text" className="form-control" defaultValue={profileItem && profileItem.address2} onChange={(e)=>handleInput(e,'address2')}/>
              </div>
              <div className="btn-outer">
              <button type="button" className="button cancel" onClick={onCloseModal}>Cancel</button>
              <button type="submit" className="button green">Update</button>
              </div>
            </div>
          </form>
      </Modal>
    </>
  );
};

export default Profile;
