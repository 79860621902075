import React, { useState,useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from 'react-router-dom';
import { getTrending } from "../../utility/apiService";
import ReactHtmlParser from 'react-html-parser';
import 'react-tabs/style/react-tabs.css';
import "./style.css";
const BestDeals = () => {
  const [tabIndex1, setTabIndex1] = useState(0);
  const [tabIndex2, setTabIndex2] = useState(2);
  //Api
 const [trendingItem, setTrendingItem] = useState([]);
 const [categoryImgPath, setCategoryImgPath] = useState([]);
 useEffect(() => {
    getTrending()
   .then((res) => {
    setTrendingItem(res.data.data)
    setCategoryImgPath(res.data.metadata.imageUrl)
   })
   .catch((error) => {
     console.error(error);
   })
 }, [])
  return (
    <section className="best-deals section">
      <div className="container">
        <div className="row">
          <div className="offset-lg-3 col-lg-6 col-md-12">
          <h3 className="heading1">Get the Best Deals and Discounts Trending right now</h3>
          </div>
        </div>
        {/* 1st row */}
        <div className="row">
          <div className="col-md-6">
            <Tabs selectedIndex={tabIndex1} onSelect={index => setTabIndex1(index)}>
              <TabList>
                {trendingItem && trendingItem.map((item,idx) => (
                  item.sort_order === '0' &&
                    <Tab key={idx}>{item.category_name}</Tab>
                ))}
              </TabList>
              {trendingItem && trendingItem.map((item,idx) => (
                item.sort_order === '0' &&
                <TabPanel key={idx}>
                  <h4>{item.title}</h4>
                  {ReactHtmlParser(item.description)}
                  <Link to={`/${item.category_slug}`} className="green-link">View best {item.category_name}</Link>
                </TabPanel>
              ))}
            </Tabs>
          </div>
          <div className="col-md-6">
          {trendingItem && trendingItem.map((item,idx) => (
            item.sort_order === '0' && idx === tabIndex1 &&
            <div className="row" key={idx}>
              <div className="col-6 col-sm-6">
                <figure className="deal-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                  <LazyLoadImage alt={item.title} effect="blur" src={`${categoryImgPath}${item.image_name1}`} />
                </figure> 
              </div>
              <div className="col-6 col-sm-6">
                <figure className="deal-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                  <LazyLoadImage alt={item.title} effect="blur" src={`${categoryImgPath}${item.image_name2}`} />
                </figure> 
              </div>
              <div className="col-6 col-sm-6">
                <figure className="deal-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                  <LazyLoadImage alt={item.title} effect="blur" src={`${categoryImgPath}${item.image_name3}`} />
                </figure> 
              </div>
              <div className="col-6 col-sm-6">
                <figure className="deal-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                  <LazyLoadImage alt={item.title} effect="blur" src={`${categoryImgPath}${item.image_name4}`} />
                </figure> 
              </div>
            </div>
           ))}
          </div>
        </div>
        {/* 2nd row */}
        <div className="row box-switch">
          <div className="col-md-6">
            <Tabs>
              <TabList>
                {trendingItem && trendingItem.map((item,idx) => (
                  item.sort_order === '1' &&
                    <Tab onClick={()=>setTabIndex2(idx)} key={idx}>{item.category_name}</Tab>
                ))}
              </TabList>
              {trendingItem && trendingItem.map((item,idx) => (
                item.sort_order === '1' &&
                <TabPanel key={idx}>
                  <h4>{item.title}</h4>
                  {ReactHtmlParser(item.description)}
                  <Link to={`/${item.category_slug}`} className="green-link">View best {item.category_name}</Link>
                </TabPanel>
              ))}
            </Tabs>
          </div>
          <div className="col-md-6">
          {trendingItem && trendingItem.map((item,idx) => (
            item.sort_order === '1' && idx === tabIndex2 &&
            <div className="row" key={idx}>
              <div className="col-6 col-sm-6">
                <figure className="deal-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                  <LazyLoadImage alt={item.title} effect="blur" src={`${categoryImgPath}${item.image_name1}`} />
                </figure> 
              </div>
              <div className="col-6 col-sm-6">
                <figure className="deal-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                  <LazyLoadImage alt={item.title} effect="blur" src={`${categoryImgPath}${item.image_name2}`} />
                </figure> 
              </div>
              <div className="col-6 col-sm-6">
                <figure className="deal-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                  <LazyLoadImage alt={item.title} effect="blur" src={`${categoryImgPath}${item.image_name3}`} />
                </figure> 
              </div>
              <div className="col-6 col-sm-6">
                <figure className="deal-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                  <LazyLoadImage alt={item.title} effect="blur" src={`${categoryImgPath}${item.image_name4}`} />
                </figure> 
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BestDeals;
