import React, {} from 'react';
import './style.css'

const servicesTopHead = ({title1,short_description1}) => {
    return(
        <div className="section pb-0">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-1 col-lg-8 col-md-12">
                    <div className="head text-left">
                    <h3 className="heading1">{title1}</h3>
                    <p>{short_description1}</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
}

export default servicesTopHead;