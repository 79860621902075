import React, { useEffect } from "react";
import TermAndConditionBanner from '../../component/TermAndConditionBanner';
import CitySearch from "../../component/CitySearch";
import TermAndConditionContent from '../../component/TermAndConditionContent';
const TermAndCondition = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <>
      <TermAndConditionBanner />
      {/* <section className="city-outer white-bg">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
            <CitySearch/>
            </div>
          </div>
        </div>
      </section> */}
      <TermAndConditionContent />
    </>
  );
};

export default TermAndCondition;
