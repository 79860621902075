import React, { useEffect,useState } from "react";
import "./style.css";
import { getHomeData } from "../../utility/apiService";
const TopStrip = () => {
  const [homeItem, setHomeItem] = useState([]);
  useEffect(() => {
    getHomeData()
    .then((res) => {
      setHomeItem(res.data.data[0]);
    })
    .catch((error) => {
      console.error(error);
    })
    window.scrollTo(0,0);
  }, [])
  const {flash_msg_isenable,flash_msg_text,flash_msg_link_text,flash_msg_link_url} = homeItem;
  return (
    flash_msg_isenable === '1' && 
    <section className="top-strip">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <p><span title={flash_msg_text}>{flash_msg_text}</span> <a href={flash_msg_link_url} target="_blank" rel="noreferrer">{flash_msg_link_text}</a></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopStrip;
