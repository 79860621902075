import React, {} from 'react';
import { Link } from 'react-router-dom';
import './style.css'

const BlogPopularArticle = ({popular}) => {
    return(
        <>
        {popular && popular.length > 0 &&
        <div className="categories popular-article">
            <h3>Popular articles</h3>
            <ul className="category-list">
            {popular && popular.map((item,idx) => (
                item.ispopular === '1' &&
                <li key={idx}><Link to={'/blog/'+item.slug}>{item.title}</Link></li>
            ))}
            </ul>   
        </div>
        }
        </>
    )
}

export default BlogPopularArticle;