import React from 'react';
import PageNotFoundBanner from '../../component/PageNotFound-Banner';
import PageNotFoundContent from '../../component/PageNotFound-Content';
const PageNotFound = () => {
  return (
    <>
      <PageNotFoundBanner />
      <PageNotFoundContent />
    </>
  );
};

export default PageNotFound;
