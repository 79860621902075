import React, { useState,useEffect } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {checkLogin} from "../../Action/actions";
import { Link,NavLink,useLocation, useHistory } from "react-router-dom";
import UserProfile from "../../component/UserProfile";
import { FaFacebookF,FaTwitter,FaGoogle } from 'react-icons/fa';
import { getCommonData , postSignup, postSignin,postForgotPassword , getSocialToken} from "../../utility/apiService";
import { Modal } from 'react-responsive-modal';
import useLoader from "../../hook/useLoader";
import {toast} from 'react-toastify';
import Facebook from '../../component/FacebookLogin';
import Google from '../../component/GoogleLogin';
import firebase from "../../firebase";
import 'firebase/auth';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';
import "./style.css";

const Header = () => {
  const dispatch = useDispatch();
  const CheckLogin = useSelector(
    ({ commonReducer }) => commonReducer.isLogin
  );
  const [loader,showLoader] = useLoader();
  const [userStatus, setUserStatus] = useState(true);
    useEffect(() => {
    const userInfoFromLS = localStorage.getItem("user_info");
    let userInfo = null;
    if(userInfoFromLS) {
      userInfo = JSON.parse(userInfoFromLS);
    }
    
    if (userInfo) {
      setUserStatus(false)
    }
  }, [])
  //popup
  const [openSignIn, setOpenSignIn] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const history = useHistory();
  const onOpenModal = (key) =>{
    if(key ==='signIn'){
      setOpenSignUp(false);
      setOpenSignIn(true);
      setShowForgotP(false);
      document.body.classList.remove('mob-menu-open');
      setErrorEmptySign({
        emailError : false,
        passwordError : false,
        validEmailError : false,
      });
      setInputValSign({
        email:'',
        password:'',
      })
      setCheckValidationSign(false)
    }else{
      setOpenSignIn(false);
      setOpenSignUp(true);
      dispatch(checkLogin(false))
      document.body.classList.remove('mob-menu-open');
      setErrorEmpty({
        nameError : false,
        emailError : false,
        passwordError : false,
        confirmPasswordError : false,
        validEmailError : false,
        validPasswordError: false
      });
      setInputVal({
        name:'',
        email:'',
        password:'',
        confirm_password:'',
      })
      setCheckValidation(false)
    }
  } 
  const onCloseModal = () => {
    setOpenSignIn(false);
    dispatch(checkLogin(false))
    setOpenSignUp(false);
    /*SignUp*/
    setErrorEmpty({
      nameError : false,
      emailError : false,
      passwordError : false,
      confirmPasswordError : false,
      validEmailError : false,
      validPasswordError: false,
    });
    setInputVal({
      name:'',
      email:'',
      password:'',
      confirm_password:'',
    })
    setCheckValidation(false)
    /*SignIn*/
    setErrorEmptySign({
      emailError : false,
      passwordError : false,
      validEmailError : false,
    });
    setInputValSign({
      email:'',
      password:'',
    })
    setCheckValidationSign(false)
  };
  //Open Close NAV
  const openMobMenu = () => {
    window.screen.width <= 991 && document.body.classList.add('mob-menu-open');
  }
  const hideMobMenu = () => {
    window.screen.width <= 991 && document.body.classList.remove('mob-menu-open');
  }
  //Mob Sub Menu Toggle
  const [id, setid] = useState(false);
  const addClass = (e) => {
    setid(e);
    document.querySelectorAll('.sub-menu-arrow')[e].classList.toggle('active');
  };
  //Show Forgot Password
  const [showForgotP, setShowForgotP] = useState(false);
  const showForgotPBox = (e) => {
    setShowForgotP(true);
  };
  //Api
  const [menuItem, setMenuItem] = useState([]);
  useEffect(() => {
    getCommonData()
    .then((res) => {
      setMenuItem(res.data.data.categories)
    })
    .catch((error) => {
      console.error(error);
    })
  }, [])
  const location = useLocation()

  /*handleSignUpSubmit*/
  const [inputVal, setInputVal] = useState({
    name:'',
    email:'',
    password:'',
    confirm_password:'',
  });
  const handleInput = (e) =>{
    setInputVal({
      ...inputVal,
      [e.target.name]:e.target.value
    })
  }
  const [isErrorEmpty, setErrorEmpty] = useState({
    nameError : false,
    emailError : false,
    passwordError : false,
    confirmPasswordError : false,
    validEmailError : false,
    validPasswordError: false
  });
  const [checkValidation, setCheckValidation] = useState(false)
  const handleSignUpSubmit=(e)=>{
    e.preventDefault()
    setErrorEmpty({
      nameError : false,
      emailError : false,
      passwordError : false,
      confirmPasswordError : false,
      validEmailError : false,
      validPasswordError: false
    });
    handleValidation()
    setCheckValidation(true)
  } 
  useEffect(() => {
    const payload = {
      name: inputVal.name,
      email: inputVal.email,
      password: inputVal.password,
      device_id: "12345"
    }
    if(checkValidation && !isErrorEmpty.nameError && !isErrorEmpty.emailError && !isErrorEmpty.passwordError && !isErrorEmpty.confirmPasswordError &&
      !isErrorEmpty.validPasswordError && !isErrorEmpty.validEmailError){
      postSignup(payload)
      .then((res) => {
        if(res.data.code === 200) {
          localStorage.setItem("user_info", JSON.stringify(res.data.data));
          localStorage.setItem("meta_data", JSON.stringify(res.data.metadata));
          setOpenSignUp(false);
          showLoader()
          toast.success(res.data.message)
          setTimeout(() => {
            history.push('/profile');
            window.location.reload()
          }, 5000);
        }
        if(res.data.code === 409) {
          toast.error(res.data.message)
        }
      })
      .catch((error) => {
        console.error(error);
      })
    }
    setCheckValidation(false)
 },[checkValidation])
 
 const handleValidation=()=>{
    if(inputVal.name ===''){  
      setErrorEmpty(prevError => {
        return { 
          ...prevError, 
          nameError: true
        }
      })
    }
    if(inputVal.email ===''){  
      setErrorEmpty(prevError => {
        return { 
          ...prevError, 
          emailError: true
        }
      })
    }else{
      let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!regEmail.test(inputVal.email)){
        setErrorEmpty(prevError => {
          return { 
            ...prevError, 
            validEmailError: true
          }
        })
      }
    }
    if(inputVal.password ===''){  
      setErrorEmpty(prevError => {
        return { 
          ...prevError, 
          passwordError: true
        }
      })
    }
    if(inputVal.confirm_password ===''){  
      setErrorEmpty(prevError => {
        return { 
          ...prevError, 
          confirmPasswordError: true
        }
      })
    }else if(inputVal.confirm_password!==inputVal.password){
      setErrorEmpty(prevError => {
        return { 
          ...prevError, 
          validPasswordError: true
        }
      })
    }
  }

  /*handleSignInSubmit*/
  const [inputValSign, setInputValSign] = useState({
    email:'',
    password:'',
  });
  const handleInputSign = (e) =>{
    setInputValSign({
      ...inputValSign,
      [e.target.name]:e.target.value
    })
  }
  const [isErrorEmptySign, setErrorEmptySign] = useState({
    emailError : false,
    passwordError : false,
    validEmailError : false,
  });
  const [checkValidationSign, setCheckValidationSign] = useState(false)
  const handleSignInSubmit=(e)=>{
    e.preventDefault()
    setErrorEmptySign({
      emailError : false,
      passwordError : false,
      validEmailError : false,
    });
    handleValidationSign()
    setCheckValidationSign(true)
  } 
  useEffect(() => {
    const payload = {
      email: inputValSign.email,
      password: inputValSign.password,
      device_id: "12345"
    }
    if(checkValidationSign && !isErrorEmptySign.emailError && !isErrorEmptySign.passwordError && !isErrorEmptySign.validEmailError){
      postSignin(payload)
      .then((res) => {
        if(res.data.code === 200) {
          localStorage.setItem("user_info", JSON.stringify(res.data.data));
          localStorage.setItem("meta_data", JSON.stringify(res.data.metadata));
          window.location.reload();
        }
        if(res.data.code === 401) {
          toast.error(res.data.message)
        }
      })
      .catch((error) => {
        console.error(error);
      })
    }
    setCheckValidationSign(false)
  },[checkValidationSign])
  const handleValidationSign=()=>{
    if(inputValSign.email === ''){  
      setErrorEmptySign(prevError => {
        return { 
          ...prevError, 
          emailError: true
        }
      })
    }else{
      let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!regEmail.test(inputValSign.email)){
        setErrorEmptySign(prevError => {
          return { 
            ...prevError, 
            validEmailError: true
          }
        })
      }
    }
    if(inputValSign.password ===''){  
      setErrorEmptySign(prevError => {
        return { 
          ...prevError, 
          passwordError: true
        }
      })
    }
  }
  /*Forgot Password*/
  const [inputForPassEmail, setInputForPassEmail] = useState('');
  const handleForPassInput = (e) =>{
    setInputForPassEmail(e.target.value)
  }
  const [isForPassError, setForPassError] = useState(false);
  const [isForPassErrorEmpty, setForPassErrorEmpty] = useState(false);
  const handleForPassSubmit=(e)=>{
    e.preventDefault()
    setForPassError(false)
    setForPassErrorEmpty(false)
    handleForPassValidation()
    let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(regEmail.test(inputForPassEmail)){
      postForgotPassword({email:inputForPassEmail})
      .then((res) => {
        if(res.data.code === 200) {
          setOpenSignIn(false);
          showLoader()
          toast.success(res.data.message)
          setTimeout(() => {
            window.location.reload()
          }, 5000);
        }
        if(res.data.code === 401) {
          toast.error(res.data.message)
        }
      })
      .catch((error) => {
        console.error(error);
      })
    }
  } 
  const handleForPassValidation=()=>{
    if(inputForPassEmail===''){  
      setForPassErrorEmpty(true)
    }else{
      let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!regEmail.test(inputForPassEmail)){
        setForPassError(true);
      }  
    }
  }
  /*FB Signin */
  const responseFacebook = (response) => {
    if(!!response && !!response.accessToken){
      getSocialToken()
      .then((res) => {
        const payload = {
          name: response.name,
          email: response.email,
          temp_token: res.data.data.temp_token,
          social_photo: response.picture.data.url,
          password: response.accessToken,
          device_id: '12345',
        }
        postSignup(payload)
        .then((res) => {
          localStorage.setItem("user_info", JSON.stringify(res.data.data)); 
          window.location.reload()
        })
        .catch((error) => {
          console.error(error);
        })
      })
      .catch((error) => {
        console.error(error);
      })
      setOpenSignUp(false);
      setOpenSignIn(false);
      showLoader()
    }
  }
  /*Gmail Signin */
  const responseGoogle = (response) => {
    if(!!response && !!response.accessToken){
      getSocialToken()
      .then((res) => {
        const payload = {
          name: response.profileObj.name,
          email: response.profileObj.email,
          temp_token: res.data.data.temp_token,
          social_photo: response.profileObj.imageUrl,
          password: response.accessToken,
          device_id: '12345',
        }
        postSignup(payload)
        .then((res) => {
          localStorage.setItem("user_info", JSON.stringify(res.data.data)); 
          window.location.reload()
        })
        .catch((error) => {
          console.error(error);
        })
      })
      .catch((error) => {
        console.error(error);
      })
      showLoader()
    }
  };
  /*Twitter Signin */
  const twitterClick = () => {
    const provider = new firebase.auth.TwitterAuthProvider();
    firebase
    .auth()
    .signInWithPopup(provider)
    .then((res) => {
      if(!!res && !!res.credential.accessToken){
        getSocialToken()
        .then((result) => {
          const payload = {
            name: res.user.displayName,
            email: res.user.email,
            temp_token: result.data.data.temp_token,
            social_photo: res.user.photoURL,
            password: res.credential.accessToken,
            device_id: '12345',
          }
          postSignup(payload)
          .then((resp) => {
            localStorage.setItem("user_info", JSON.stringify(resp.data.data)); 
            window.location.reload()
          })
          .catch((error) => {
            console.error(error);
          })
        })
        .catch((error) => {
          console.error(error);
        })
        showLoader()
      }
    })
  }
  return (
    <>
    {loader}
    {/* <ToastContainer position="top-center"/> */}
    <header className="header">
      <div className="container">
        <div className="row header-inner">
          <div className="col-lg-2 col-sm-3">
            <Link to='/' className="logo"><img src="../images/logo.svg" alt="Logo"/></Link>
          </div>
          <div className="col-md-10 col-sm-9">
          <div className="menu-icon" onClick={openMobMenu}>
              <span className="bar1"></span>
              <span className="bar2"></span>
              <span className="bar3"></span>
          </div>
          <div className="header-right">
          <nav>
            <div className="mob-nav-overlay" onClick={hideMobMenu}></div>
            <div className="menu-icon active" onClick={hideMobMenu}> 
              <span className="bar1"></span>
              <span className="bar2"></span>
              <span className="bar3"></span>
            </div>
            <ul className="menu">
              {menuItem && menuItem.map((item,idx) => {
              return (
              <li key={idx}>
                <NavLink to={'/'+item.slug} onClick={hideMobMenu}>
                  {item.name}
                </NavLink>
                <div className={`sub-menu-arrow ${idx === id ? 'active' : ' '}`} onClick={() => {addClass(idx)}}></div>
                <ul className="sub-menu">
                  {item.subcategory.map((i,idx) => (
                  <li key={idx}><NavLink to={'/'+item.slug+'/'+i.slug} onClick={hideMobMenu}>{i.name}</NavLink></li>
                  ))}
                </ul>
              </li>)})}
              <li>
                <Link to='/blog' className={location.pathname ==='/blog' ? 'active':''} onClick={hideMobMenu}>Blog</Link>
              </li>
            </ul>
            {userStatus &&
              <ul className="header-login">
                <li>
                  <Link to='#' onClick={onOpenModal}>Sign Up</Link>
                </li>
                <li>
                  <button className="sign-in" onClick={()=>onOpenModal('signIn')}>Sign In</button>
                </li>
              </ul>
            }
          </nav>
          {!userStatus &&
            <UserProfile/>
          }
          </div>
        </div>
        </div>
      </div>

      {/* SignIn PopUp */}
      <Modal open={CheckLogin ? CheckLogin : openSignIn} onClose={onCloseModal} center modalId="signin-popup">
          <div className="medel-head text-center">
            <figure><img src="../images/price-icon.png" alt="Tcc"/></figure>
            <h3>Welcome Back!</h3>
            <p>We'd love to help you find the best deals around you</p>
          </div>
           <ul className="login-list">
                <li>
                <strong>Join us with:</strong>
                </li>
                <li>
                  <Facebook responseFacebook={responseFacebook}/>
                  <a href="#" className="facebook"><FaFacebookF/></a>
                </li>
                
                <li>
                  <Google responseGoogle={responseGoogle}/>
                  <a href="#" className="gmail"><FaGoogle/></a>
                </li>
                <li>
                  <a href="javascript:void(0)" onClick={()=>twitterClick()} className="twitter"><FaTwitter/> </a>
                </li>
            </ul>
            {!showForgotP && 
            <>
            <div className="or-text"><strong>Or Sign In with</strong></div>
            <form className="login-form" onSubmit={(e)=>handleSignInSubmit(e)}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group mb-3">
                    <label>Email <span className="error-star">*</span></label>
                    <input type="text" name="email" className="form-control" onChange={(e)=>handleInputSign(e)}/>
                    {isErrorEmptySign.emailError &&
                    <p className="error">Please Enter Email</p>}
                    {isErrorEmptySign.validEmailError &&
                    <p className="error">Please Enter Valid Email</p>}
                  </div>
                </div>  
                <div className="col-sm-6">
                  <div className="form-group mb-3">
                    <label>Password <span className="error-star">*</span></label>
                    <input type="password" name="password" className="form-control" onChange={(e)=>handleInputSign(e)}/>
                    {isErrorEmptySign.passwordError &&
                    <p className="error">Please Enter Password</p>}
                  </div>
                </div>
                <div className="col-sm-12 text-right">
                  <button className="green-link" onClick={showForgotPBox}>Forgot Password</button>
                </div>
              </div>
              <div className="btn-outer">
                <button type="button" className="button cancel" onClick={onCloseModal}>Cancel</button>
                <button type="submit" className="button green">Sign In</button>
              </div>
            </form>
            </>}
            {/* Forgot Password */}
            {showForgotP &&
            <>
            <div className="or-text"><strong>Forgot Password</strong></div>
            <form className="forgot-password" onSubmit={handleForPassSubmit}>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group mb-3">
                    <label>Email <span className="error-star">*</span></label>
                    <input type="text" className="form-control" onChange={(e)=>handleForPassInput(e)}/>
                    {isForPassError &&
                      <p className="error">Please Enter Valid Email</p>
                    } 
                    {isForPassErrorEmpty &&
                      <p className="error">Please Enter Email</p>
                    }
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group mb-3 text-center">
                    <button type="submit" className="button green">Submit</button>  
                  </div>
                </div>
              </div>
            </form>
            </>}
            <p>
            <small>Sign In indicates you agree to our 
            <Link to='/term-condition' onClick={onCloseModal}>Terms and Services</Link>
            and <Link to='/privacy-policy' onClick={onCloseModal}>Privacy Policy</Link>
            </small>
          </p>
          <div className="already-signup">
              <span>Don't have an account? <button type="button" className="green-link" onClick={onOpenModal}>Sign Up</button></span>      
          </div>
      </Modal>

      {/* SignUp PopUp */}
      <Modal open={openSignUp} onClose={onCloseModal} center modalId="signup-popup">
          <div className="medel-head text-center">
            <figure><img src="../images/price-icon.png" alt="Tcc"/></figure>
            <h3>Welcome Aboard!</h3>
            <p>We'd love to help you find the best deals around you</p>
          </div>
          <ul className="login-list">
                <li>
                <strong>Join us with:</strong>
                </li>
                <li>
                  <Facebook responseFacebook={responseFacebook}/>
                  <a href="#" className="facebook"><FaFacebookF/></a>
                </li>
                
                <li>
                  <Google responseGoogle={responseGoogle}/>
                  <a href="#" className="gmail"><FaGoogle/></a>
                </li>
                <li>
                  <a href="javascript:void(0)" onClick={()=>twitterClick()} className="twitter"><FaTwitter/> </a>
                </li>
            </ul>
            <div className="or-text"><strong>Or Sign Up with</strong></div>
            <form className="login-form" onSubmit={(e)=>handleSignUpSubmit(e)}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group mb-3">
                    <label>Name <span className="error-star">*</span></label>
                    <input type="text" name="name" className="form-control" onChange={(e)=>handleInput(e)}/>
                    {isErrorEmpty.nameError &&
                    <p className="error">Please Enter Name</p>}
                  </div>
                </div>  
                <div className="col-sm-6">
                  <div className="form-group mb-3">
                    <label>Email <span className="error-star">*</span></label>
                    <input type="text" name="email" className="form-control" onChange={(e)=>handleInput(e)}/>
                    {isErrorEmpty.emailError &&
                    <p className="error">Please Enter Email</p>}
                    {isErrorEmpty.validEmailError &&
                    <p className="error">Please Enter Valid Email</p>}
                  </div>
                </div>  
                <div className="col-sm-6">
                <div className="form-group mb-3">
                  <label>Password <span className="error-star">*</span></label>
                  <input type="password" name="password" className="form-control" onChange={(e)=>handleInput(e)}/>
                  {isErrorEmpty.passwordError &&
                    <p className="error">Please Enter Password</p>}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group mb-3">
                  <label>Confirm Password <span className="error-star">*</span></label>
                  <input type="password" name="confirm_password" className="form-control" onChange={(e)=>handleInput(e)}/>
                  {isErrorEmpty.confirmPasswordError &&
                    <p className="error">Please Enter Confirm Password</p>}
                    {isErrorEmpty.validPasswordError && 
                    <p className="error">Password doesn't match</p>}
                </div>
              </div>
              </div>
                <div className="btn-outer">
                <button type="button" className="button cancel" onClick={onCloseModal}>Cancel</button>
                <button type="submit" className="button green">Sign Up</button>
                </div>
            </form>
          <p>
            <small>Sign Up indicates you agree to our 
            <Link to='/term-condition' onClick={onCloseModal}>Terms and Services</Link>
            and <Link to='/privacy-policy' onClick={onCloseModal}>Privacy Policy</Link>
            </small>
          </p>
          <div className="already-signup">
              <span>Already have an account? <button type="button" className="green-link" onClick={()=>onOpenModal('signIn')}>Sign In</button></span>      
          </div>
      </Modal>
    </header>
    </>
  );
};

export default Header;
