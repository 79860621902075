import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './style.css';

const AboutBanner = () => {
  return (
    <div className="banner">
      <div className="container">
          <div className="row banner-inner">
              <div className="offset-lg-1 col-lg-5 col-md-6 banner-left pb-30">
                <h1>About us</h1>
                <p>Know everything about us and how we help you compare and choose!</p>
              </div>
              <div className="col-lg-4 col-md-5 offset-lg-2 offset-md-1 banner-right">
                  <LazyLoadImage src="../images/about-us.png" alt="About Banner" />
              </div>
          </div>
      </div>
    </div>
  );
};

export default AboutBanner;
