import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import { postPartner } from "../../utility/apiService";
import useLoader from "../../hook/useLoader";
import {toast } from 'react-toastify';
import './style.css'

const PartnerForm = () => {
    const [loader,showLoader, hideLoader] = useLoader();
    const [inputVal, setInputVal] = useState({
        company_name:'',
        website:'',
        email:'',
        phone_number:'',
        first_name:'',
        last_name:'',
        hear_from: '',
        address1:'',
        address2:'',
        city:'',
        state:'',
        //zip_code:'',
        message:'',
      });
      const handleInput = (e) =>{
        setInputVal({
          ...inputVal,
          [e.target.name]:e.target.value
        })
      }
      const [isErrorEmpty, setErrorEmpty] = useState({
        companyError : false,
        websiteError : false,
        emailError : false,
        validEmailError: false,
        phoneError : false,
        firstNameError : false,
        lastNameError: false,
        hearFromError: false,
        addressError: false,
        cityError: false,
        stateError: false,
        //zipCodeError: false,
        messageError: false,
      });
      const [checkValidation, setCheckValidation] = useState(false)
      const handleSubmit=(e)=>{
        e.preventDefault()
        setErrorEmpty({
            companyError : false,
            websiteError : false,
            emailError : false,
            validEmailError: false,
            phoneError : false,
            firstNameError : false,
            lastNameError: false,
            hearFromError: false,
            addressError: false,
            cityError: false,
            stateError: false,
            //zipCodeError: false,
            messageError: false,
        });
        handleValidation()
        setCheckValidation(true)
      } 
      useEffect(() => {
        const payload = {
            company_name:inputVal.company_name,
            website:inputVal.website,
            email:inputVal.email,
            phone_number:inputVal.phone_number,
            first_name:inputVal.first_name,
            last_name:inputVal.last_name,
            hear_from: inputVal.hear_from,
            address1:inputVal.address1,
            address2:inputVal.address2,
            city:inputVal.city,
            state:inputVal.state,
            //zip_code:inputVal.zip_code,
            message:inputVal.message,
            device_id: "12345"
        } 
        if(checkValidation && !isErrorEmpty.companyError && !isErrorEmpty.websiteError && !isErrorEmpty.emailError && !isErrorEmpty.phoneError &&
          !isErrorEmpty.firstNameError && !isErrorEmpty.lastNameError && !isErrorEmpty.addressError && !isErrorEmpty.hearFromError && !isErrorEmpty.cityError 
          && !isErrorEmpty.stateError && !isErrorEmpty.messageError && !isErrorEmpty.validEmailError){
          showLoader()
          postPartner(payload)
          .then((res) => {
            if(res.data.code === 200) {
                toast.success(res.data.message)
                setInputVal({
                    company_name:'',
                    website:'',
                    email:'',
                    phone_number:'',
                    first_name:'',
                    last_name:'',
                    hear_from: '',
                    address1:'',
                    address2:'',
                    city:'',
                    state:'',
                    //zip_code:'',
                    message:'',
                })
            }
            if(res.data.code === 409) {
              toast.error(res.data.message)
            }
            hideLoader()
          })
          .catch((error) => {
            hideLoader()  
            console.error(error);
          })
        }
        setCheckValidation(false)
     },[checkValidation])
     
    const handleValidation=()=>{
        if(inputVal.company_name ===''){  
          setErrorEmpty(prevError => {
            return { 
              ...prevError, 
              companyError: true
            }
          })
        }
        if(inputVal.website ===''){  
          setErrorEmpty(prevError => {
            return { 
            ...prevError, 
            websiteError: true
            }
           })
        }
        if(inputVal.email ===''){  
          setErrorEmpty(prevError => {
            return { 
              ...prevError, 
              emailError: true
            }
          })
        }else{
            let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!regEmail.test(inputVal.email)){
                setErrorEmpty(prevError => {
                    return { 
                    ...prevError, 
                    validEmailError: true
                    }
                })
            }
        }
        if(inputVal.phone_number ===''){  
            setErrorEmpty(prevError => {
                return { 
                    ...prevError, 
                    phoneError: true
                }
            })
        }
        if(inputVal.first_name ===''){  
            setErrorEmpty(prevError => {
                return { 
                    ...prevError, 
                    firstNameError: true
                }
            })
        }
        if(inputVal.last_name ===''){  
            setErrorEmpty(prevError => {
                return { 
                ...prevError, 
                lastNameError: true
                }
            })
        }
        if(inputVal.address1 ===''){  
            setErrorEmpty(prevError => {
                return { 
                ...prevError, 
                addressError: true
                }
            })
        }
        if(inputVal.hear_from ===''){  
            setErrorEmpty(prevError => {
                return { 
                ...prevError, 
                hearFromError: true
                }
            })
        }
        if(inputVal.city ===''){  
            setErrorEmpty(prevError => {
                return { 
                ...prevError, 
                cityError: true
                }
            })
        }
        if(inputVal.state ===''){  
            setErrorEmpty(prevError => {
                return { 
                ...prevError, 
                stateError: true
                }
            })
        }
        // if(inputVal.zip_code ===''){  
        //     setErrorEmpty(prevError => {
        //         return { 
        //         ...prevError, 
        //         zipCodeError: true
        //         }
        //     })
        // }
        if(inputVal.message ===''){  
            setErrorEmpty(prevError => {
                return { 
                ...prevError, 
                messageError: true
                }
            })
        }
    }
    return(
      <>
      {loader}
        <div className="contact-outer section">
            <div className="container">
                <div className="row">
                    <div className="offset-lg-1 col-lg-10  col-md-12">
                        <div className="head text-left">
                            <h3 className="heading1">Partners</h3>
                            <p>Thank you for your interest. Please fill in your contact details below.</p>
                        </div>
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="offset-lg-1 col-lg-10 col-md-12">
                        <form className="partner-form" onSubmit={handleSubmit}>
                            <div className="form-group mb-3">
                                <label>Company Name <span className="error-star">*</span></label>
                                <input type="text" value={inputVal.company_name} name="company_name" className="form-control" onChange={(e)=>handleInput(e)}/>
                                {isErrorEmpty.companyError &&
                                <p className="error">Please Enter Company Name</p>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Website <span className="error-star">*</span></label>
                                <input type="text" value={inputVal.website} name="website" className="form-control" onChange={(e)=>handleInput(e)}/>
                                {isErrorEmpty.websiteError &&
                                <p className="error">Please Enter Website Url</p>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Email <span className="error-star">*</span></label>
                                <input type="text" value={inputVal.email} name="email" className="form-control" onChange={(e)=>handleInput(e)}/>
                                {isErrorEmpty.emailError &&
                                <p className="error">Please Enter Email</p>}
                                {isErrorEmpty.validEmailError &&
                                <p className="error">Please Enter Valid Email</p>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Phone Number <span className="error-star">*</span></label>
                                <input type="text" value={inputVal.phone_number} name="phone_number" className="form-control" onChange={(e)=>handleInput(e)}/>
                                {isErrorEmpty.phoneError &&
                                <p className="error">Please Enter Company Name</p>}
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                        <label>First Name <span className="error-star">*</span></label>
                                        <input type="text" value={inputVal.first_name} name="first_name" className="form-control" onChange={(e)=>handleInput(e)}/>
                                        {isErrorEmpty.firstNameError &&
                                        <p className="error">Please Enter First Name</p>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                        <label>Last Name <span className="error-star">*</span></label>
                                        <input type="text" value={inputVal.last_name} name="last_name" className="form-control" onChange={(e)=>handleInput(e)}/>
                                        {isErrorEmpty.lastNameError &&
                                        <p className="error">Please Enter Last Name</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="hear-about mt-2">
                                <p>How did you hear about The Comparison Company? <span className="error-star">*</span></p> 
                                <select className="form-select" name="hear_from" onChange={(e)=>handleInput(e)}>
                                    <option value="">Select</option>
                                    <option value="Advertisements">Advertisements</option>
                                    <option value="Adwords">Adwords</option>
                                    <option value="Email">Email</option>
                                    <option value="Event">Event</option>
                                    <option value="Website">Website</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="linkedin">linkedin</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="Twitter">Twitter</option>
                                    <option value="Youtube">Youtube</option>
                                    <option value="Web Research">Web Research</option>
                                    <option value="Existing TCC Partner">Existing TCC Partner</option>
                                    <option value="Existing TCC Customer">Existing TCC Customer</option>
                                    <option value="The TCC Website">The TCC Website</option>
                                    <option value="Other">Other</option>
                                </select>
                                {isErrorEmpty.hearFromError &&
                                <p className="error">Please Select Any One Option</p>}
                            </div>
                            <h3 className="pt-4">Business Address</h3>
                            <div className="form-group mb-3">
                                <label>Address Line 1 <span className="error-star">*</span></label>
                                <input type="text" value={inputVal.address1} name="address1" className="form-control" onChange={(e)=>handleInput(e)}/>
                                {isErrorEmpty.addressError &&
                                <p className="error">Please Enter Business Address</p>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Address Line 2</label>
                                <input type="text" value={inputVal.address2} name="address2" className="form-control" onChange={(e)=>handleInput(e)}/>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                        <label>City/Town <span className="error-star">*</span></label>
                                        <input type="text" value={inputVal.city} name="city" className="form-control" onChange={(e)=>handleInput(e)}/>
                                        {isErrorEmpty.cityError &&
                                        <p className="error">Please Enter City/Town</p>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-3">
                                        <label>State <span className="error-star">*</span></label>
                                        <input type="text" value={inputVal.state} name="state" className="form-control" onChange={(e)=>handleInput(e)}/>
                                        {isErrorEmpty.stateError &&
                                        <p className="error">Please Enter State</p>}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="form-group mb-3">
                                <label>Zip Code <span className="error-star">*</span></label>
                                <input type="text" name="zip_code" className="form-control" onChange={(e)=>handleInput(e)} />
                                {isErrorEmpty.zipCodeError &&
                                <p className="error">Please Enter Zip Code</p>}
                            </div> */}
                            <div className="form-group mb-3">
                                <label>Message <span className="error-star">*</span></label>
                                <textarea name="message" value={inputVal.message} className="form-control" onChange={(e)=>handleInput(e)}></textarea>
                                {isErrorEmpty.messageError &&
                                <p className="error">Please Write Message</p>}
                            </div>
                            <p className="pt-3 pb-3 text-center m-l-r-5">
                                I agree to the
                                <Link to='/term-condition'>Terms and Services</Link>
                                and <Link to='/privacy-policy'>Privacy Policy</Link>
                            </p>
                            <div className="btn-outer">
                                <button type="submit" className="button green">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}

export default PartnerForm;