import React, {} from 'react';
import { Link } from 'react-router-dom';
import './style.css'

const BlogCategories = ({categoryFilter,category,blogShowAll}) => {
    return(
        <>
        {category && category.length > 0 &&
        <div className="categories blog-categories">
            <h3>Categories</h3>
            <ul className="category-list">
            {category && category.map((item,idx) => (
                <li key={idx}><Link to={{pathname:'/blog', state:{category_id:item.id}}} onClick={()=>categoryFilter(item.id)}>{item.name}</Link></li>
            ))}
            </ul>  
            <Link to='/blog' onClick={blogShowAll} className="green-link">Show All</Link> 
        </div>}
        </>
    )
}

export default BlogCategories;