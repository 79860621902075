import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { getRecentDeals } from "../../utility/apiService";
import {checkLogin} from "../../Action/actions";
import "./style.css";
import { isEmpty } from "lodash";
const RecentComparisons = () => {
  //Api
  const [recentComparison, setRecentComparison] = useState([]);
  const [categoryImgPath, setCategoryImgPath] = useState([]);
  
  useEffect(() => {
      getRecentDeals()
      .then((res) => {
        setRecentComparison(res.data.data)
        setCategoryImgPath(res.data.metadata.imageUrl)
      })
      .catch((error) => {
          console.error(error)
      })
  }, [])
  //Sign IN
  const dispatch = useDispatch();
  const OpenSignInPopup = () => {
    dispatch(checkLogin(true))
  }
  return (
    <>
    {!isEmpty(recentComparison) &&
    <section className="recent-comparison">
      <div className="container">
      <div className="row">
          <div className="offset-lg-3 col-lg-6 col-md-12">
            <h3 className="heading1">Recent Comparisons</h3>
            <p className="sub-heading-1">Take a look at the companies people around you are talking about. <br/>We have compared Top 5 for you, to help you choose the best. </p>
          </div>
        </div>
        <div className="row">
        {recentComparison && recentComparison.map((item,idx) => (
          <div className="col-6 col-lg-2 col-md-3 col-sm-4" key={idx}>
            <div className="recent-box">
              <div className="img">
              <Link to={`/company/${item.slug}`}>
                <img src={`${categoryImgPath}${item.logo_name}`} alt={item.name}/>
              </Link>
              </div>
              <strong>{item.amount}</strong>
              <span>{item.caption}</span>
              <div className="text-center">
                <Link className="button" to={`/${item.category_slug}`}>View</Link>
              </div>
            </div>  
          </div>
        ))}
        </div>
      </div>
    </section>}
    </>
  );
};

export default RecentComparisons;
