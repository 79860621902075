import React, {} from 'react';
import ReactHtmlParser from 'react-html-parser';
import './style.css'

const howCompar = ({description2}) => {
    return(
      <>
      {description2 && 
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
            <div className="rank-sec">
              {ReactHtmlParser(description2)}
            </div>
            </div>
          </div>
        </div>
      </div>}
      </>
    )
}

export default howCompar;