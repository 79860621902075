import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getCommonData } from "../../utility/apiService";
import { FaFacebookF,FaInstagram,FaTwitter } from 'react-icons/fa';
import './style.css'

const Footer = () => {
    const location = useLocation() 
    //Api
    const [menuItem, setMenuItem] = useState([]);
    useEffect(() => {
        getCommonData()
        .then((res) => {
            setMenuItem(res.data.data.categories)
        })
        .catch((error) => {
            console.error(error)
        })
    }, [])
    return(
        <div className="footer">
            {location.pathname !=='/sitemap' &&
            <div className="footer-top section">
                <div className="container">
                    <div className="footer-top-inner">
                        <h3 className="heading2">Helping you get the best best deals across all services Other Sites Just Don’t Compare.</h3>
                        <div className="row">
                        {menuItem && menuItem.map((item,idx) => (
                            item.slug !== 'business' ?    
                            <div className="col-md-4 col-sm-6" key={idx}>
                                <div className="footer-menu-outer">
                                    <h4><Link to={'/'+item.slug}>{item.name}</Link></h4>
                                    <ul className="footer-menu">
                                    {item.subcategory.map((i,idx) => (
                                        <li key={idx}><Link to={'/'+item.slug+'/'+i.slug}>{i.name}</Link></li> 
                                    ))}
                                    </ul>
                                </div>
                            </div>:null
                        ))}
                        </div>
                        <div className="footer-business">
                            {menuItem && menuItem.map((item,idx) => (
                            item.slug === 'business' ?    
                            <div key={idx}>
                            <h4><Link to={'/'+item.slug}>{item.name}</Link></h4>
                            <ul className="footer-menu">
                                {item.subcategory.map((i,idx) => (
                                    <li key={idx}><Link to={'/'+item.slug+'/'+i.slug}>{i.name}</Link></li> 
                                ))}
                            </ul>
                            </div>:null
                            ))}
                        </div>
                    </div>
                </div>
            </div>}
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-sm-3 offset-lg-1">
                            <div className="footer-logo"><img src="../images/footer-logo.svg" alt=""/></div>
                        </div>
                        <div className="col-md-3 col-sm-4 footer-comparison">
                            <p>
                                <Link to='/about-us'>About The Comparison Company</Link><br/>
                                <Link to='/term-condition'>Our Terms and Services</Link><br/>
                                <Link to='/privacy-policy'>Privacy Policy</Link>
                            </p>
                        </div>
                        <div className="col-md-3 col-sm-5 footer-comparison text-right">
                            <p>
                                <Link to='/contact-us'>Contact The Comparison Company</Link><br/>
                                <Link to='/partners-contact-us'>Partner & Supplier Sign Up</Link><br/>
                                <Link to='/sitemap'>Sitemap</Link>
                            </p>
                        </div>
                        <div className="col-md-3">
                            <a href="https://comparacion.com/" rel="noreferrer" target="_blank" className="compa-link">COMPARACION.COM</a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="footer-socials">
                                <a href="https://www.instagram.com/thecomparisonco/" rel="noreferrer" target="_blank"><FaInstagram/></a>
                                <a href="https://www.facebook.com/thecomparisoncompany/" rel="noreferrer" target="_blank"><FaFacebookF/></a>
                                <a href="https://twitter.com/thecomparisonco/" rel="noreferrer" target="_blank"><FaTwitter/></a>
                            </div>
                            <div className="copy-right">
                                <p>Copyright @ The Comparison Company</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer;