import firebase from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyBSzXnjZIi4k3sG_gYPJjRxaPhQyyOPJUA",
    authDomain: "thetcc-98e98.firebaseapp.com",
    projectId: "thetcc-98e98",
    storageBucket: "thetcc-98e98.appspot.com",
    messagingSenderId: "226396658387",
    appId: "1:226396658387:web:3b4f79f430d779b61c1226",
    measurementId: "G-0BN03SE6RD"
}

firebase.initializeApp(firebaseConfig);


export default firebase;