import React, { useState,useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useLocation,useHistory } from "react-router-dom";
import {Helmet} from "react-helmet";
import { getBlog } from "../../utility/apiService";
import ReactHtmlParser from 'react-html-parser';
import { isEmpty } from "lodash";
import './style.css'

const BlogDetailList = () => {
    //Api
    const [blogDetailItem, setBlogDetailItem] = useState([]);
    const [categoryImgPath, setCategoryImgPath] = useState([]);
    const [isLoding, setIsloading] = useState(false);
    const location = useLocation() 
    const history = useHistory()
    useEffect(() => {
    setIsloading(true) 
    let slug = location && location.pathname && location.pathname.split('/')[2]
    getBlog(slug)
    .then((res) => {
        if(!!res.data){
            setIsloading(false) 
        }
        if(isEmpty(res.data.data)){
            history.push('/page-not-found')
        }
        setBlogDetailItem(res.data.data)
        setCategoryImgPath(res.data.metadata.imageUrl)
    })
    .catch((error) => {
        console.error(error);
        setIsloading(false) 
    })
    }, [location])
    return(
        <>
        {isLoding &&
        <div className="loader" style={{backgroundImage: `url('../images/loader.png')`}}></div>
        }
        <div className="blog-detail-list">
            <div className="img">
            <figure className="blog-d-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                    <LazyLoadImage alt={blogDetailItem && blogDetailItem.title} effect="blur" src={`${categoryImgPath}${blogDetailItem && blogDetailItem.image_name}`} />
                </figure>
            </div>
            <div className="text">
                {ReactHtmlParser(blogDetailItem && blogDetailItem.description)}
            </div>
        </div>
        </>
    )
}

export default BlogDetailList;