import React, {} from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const PageNotFoundContent = () => {
  return (
    <div className="pageNotFoundContent section">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-6 offset-lg-1">
            <img src="../images/404-img.png" alt="" />
          </div>
          <div className="col-lg-5 col-md-6 offset-lg-1">
          <h3>
              Oops! <br />
              We think you turned the wrong way. Let’s go back.
            </h3>
            <p>
              Go to our <Link to="/">Home Page</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFoundContent;
