import React, { useEffect } from "react";
import PrivacyBanner from '../../component/PrivacyBanner';
import CitySearch from "../../component/CitySearch";
import PrivacyContent from '../../component/PrivacyContent';
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <>
      <PrivacyBanner />
      {/* <section className="city-outer white-bg">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
            <CitySearch/>
            </div>
          </div>
        </div>
      </section> */}
      <PrivacyContent />
    </>
  );
};

export default PrivacyPolicy;
