import React from "react";
import "./style.css";
const AboutBenifits = () => {
  return (
    <section className="about-benifits section">
      <div className="container">
        <div className="row">
          <div className="offset-lg-1 col-lg-10 col-md-12">
            <h2>Our Benefits</h2>
            <ul className="about-benifits-list">
              <li>
                <div className="img">
                <img src="../images/we_compare.svg" alt="Benefits Icon"/>
                </div>
                <div className="about-benifits-text">
                  <h4>We do the comparing</h4>
                  <p>We find the top 5 suppliers for each sector to only to show the best.</p>
                </div>
              </li>
              <li>
                <div className="img">
                <img src="../images/save_time.svg" alt="Benefits Icon"/>
                </div>
                <div className="about-benifits-text">
                  <h4>Save time</h4>
                  <p>Sign up for new services in under 5 mins & never enter your data again.</p>
                </div>
              </li>
              <li>
                <div className="img">
                <img src="../images/total_privacy.svg" alt="Benefits Icon"/>
                </div>
                <div className="about-benifits-text">
                  <h4>Total privacy - Stop spam</h4>
                  <p>We mask your contact information to stop robocalls & spam completely.</p>
                </div>
              </li>
              <li>
                <div className="img">
                <img src="../images/earn_money.svg" alt="Benefits Icon"/>
                </div>
                <div className="about-benifits-text">
                  <h4>Earn money</h4>
                  <p>Earn money towards bills by sharing prices with your friends & family.</p>
                </div>
              </li>

              <li>
                <div className="img">
                <img src="../images/track_utility.svg" alt="Benefits Icon"/>
                </div>
                <div className="about-benifits-text">
                  <h4>Track utility use & cost</h4>
                  <p>Connect all bills into one simple dashboard & see how to save money.</p>
                </div>
              </li>
              <li>
                <div className="img">
                <img src="../images/how_you_rank.svg" alt="Benefits Icon"/>
                </div>
                <div className="about-benifits-text">
                  <h4>Know how you rank</h4>
                  <p>Learn your TCC Score & see your costs compared to your neighbors.</p>
                </div>
              </li>
              <li>
                <div className="img">
                <img src="../images/automate.svg" alt="Benefits Icon"/>
                </div>
                <div className="about-benifits-text">
                  <h4>Automate bill paying</h4>
                  <p>Simplify payments, and see your real costs.</p>
                </div>
              </li>
              <li>
                <div className="img">
                <img src="../images/switch_automatically.svg" alt="Benefits Icon"/>
                </div>
                <div className="about-benifits-text">
                  <h4>Switch automatically</h4>
                  <p>Where available, automatically switch to the lowest price each month.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBenifits;
