import React, {} from 'react';
import UserProfile from "../../component/UserProfile";
import { FaCamera } from 'react-icons/fa';
import './style.css'

const EditProfile = ({onOpenModal,handleUserProfile,image,handleUpload}) => {
    return(
      <>
        <div className="edit-profilt" style={{backgroundImage: `url('../images/profile-bg.png')`}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="position-relative">
                            <UserProfile handleUserProfile={handleUserProfile} image={image} handleUpload={handleUpload}/>
                            <button className="button green edit-button" onClick={onOpenModal}>
                                <FaCamera className="camra"/> Edit Profile
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}

export default EditProfile;