import React, {} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './style.css';

const TermAndConditionBanner = () => {
  return (
    <div className="banner">
      <div className="container">
          <div className="row banner-inner">
              <div className="offset-lg-1 col-lg-5 col-md-6 banner-left pb-30">
                <h1>Terms & Condition</h1>
                <p>Understand below all the terms and conditions that we employ.</p>
              </div>
              <div className="col-lg-4 col-md-5 offset-lg-2 offset-md-1 banner-right">
                <figure className="lazy-banner-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                    <LazyLoadImage src="../images/Terms-and-Conditions.jpg" effect="blur" alt="Terms Banner"/>
                </figure> 
              </div>
          </div>
      </div>
    </div>
  );
};

export default TermAndConditionBanner;
