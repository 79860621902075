import React, { useEffect,useState,useCallback } from "react";
import {Helmet} from "react-helmet";
import { useLocation } from "react-router-dom";
import BlogBanner from "../../component/BlogBanner";
import BlogSearch from "../../component/BlogSearch";
import BlogList from "../../component/BlogList";
import BlogCategories from "../../component/BlogCategories";
import Adds from "../../component/Adds";
import BlogPopularArticle from "../../component/BlogPopularArticle";
import BlogArchive from "../../component/BlogArchive";
import { getBlogs,getBlogPageData } from "../../utility/apiService";
import { debounce,isEmpty } from "lodash";
const Blog = () => {
//Api
const [blogItem, setBlogItem] = useState([]);
const [categoryImgPath, setCategoryImgPath] = useState([]);
const [categoryId,setCategoryId] = useState(null);
const [archiveId,setArchiveId] = useState(null);
const [isLoding, setIsloading] = useState(false);
const location = useLocation()
const {state: {category_id,archive_id} = {}} = location 
//Blogs lef & category&archive filter
useEffect(() => {
  setIsloading(true) 
  getBlogs(!!category_id ? category_id : categoryId,!!archive_id ? archive_id : archiveId)
  .then((res) => {
    if(!!res.data){
      setIsloading(false) 
    }
    setBlogItem(res.data.data)
    setCategoryImgPath(res.data.metadata.imageUrl)
  })
  .catch((error) => {
      console.error(error);
      setIsloading(false) 
  })
  return () => {
    window.history.replaceState('', null);
  }
}, [category_id,categoryId,archive_id,archiveId])

const categoryFilter=(categoryId)=>{
  setCategoryId(categoryId)
}
const archiveFilter=(archiveId)=>{
  setArchiveId(archiveId)
}

//For Blog Filter
const[searchFilter,setSearchFilter]=useState('');
useEffect(() => {
  debounceBlogFilter(
    searchFilter
  );
  return () => {
    window.history.replaceState('', null);
  }
}, [searchFilter]);
const BlogFilter = (searchFilter) => {
  getBlogs(!!category_id ? category_id : categoryId,!!archive_id ? archive_id : archiveId,searchFilter)
  .then((res) => {
    setBlogItem(res.data.data)
  })
  .catch((error) => {
      console.error(error);
  })
}
const debounceBlogFilter = useCallback(
  debounce((
    searchFilter
  ) => 
  BlogFilter(
      searchFilter, 
    ), 500),
  [],
);
const handleSearch = (e) =>{
  setSearchFilter(e);
}

//Blog Show all
const blogShowAll = (e) =>{
  setCategoryId(null)
  setArchiveId(null)
  getBlogs()
  .then((res) => {
    setBlogItem(res.data.data)
  })
  .catch((error) => {
      console.error(error);
  })
}

//Banner category & Archive
const [blogDataItem, setBlogDataItem] = useState([]);
useEffect(() => {
  setIsloading(true) 
  getBlogPageData()
  .then((res) => {
    if(!!res.data){
      setIsloading(false) 
    }
    setBlogDataItem(res.data.data)
    setCategoryImgPath(res.data.metadata.imageUrl)
  })
  .catch((error) => {
      console.error(error);
      setIsloading(false) 
  })
}, [])
const {banner,meta_title,meta_description,meta_keywords,category,archive,popular,ads} = blogDataItem;
  return (
    <>
      {isLoding &&
        <div className="loader" style={{backgroundImage: `url('../images/loader.png')`}}></div>
      }
      <Helmet>
        <title>{meta_title}</title>
        <meta name="description" content={meta_description} />
        <meta name="keywords" content={meta_keywords} />
      </Helmet>
      <BlogBanner banner={banner}/>
      {/* <section className="blog-search-outer white-bg">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
            <BlogSearch handleSearch={handleSearch}/>
            </div>
          </div>
        </div>
      </section> */}
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-8 col-md-8">
              <BlogList blogItem={blogItem} categoryImgPath={categoryImgPath}/>
            </div>
            <div className="col-lg-3 col-md-4">
              {(!isEmpty(category) || !isEmpty(popular) || !isEmpty(archive) || !isEmpty(ads)) &&
              <div className="right-side-bar">
                <BlogCategories categoryFilter={categoryFilter} category={category} blogShowAll={blogShowAll}/>
                <BlogPopularArticle popular={popular}/>
                <BlogArchive archiveFilter={archiveFilter} archive={archive} blogShowAll={blogShowAll}/>
                <Adds ads={ads}/>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
