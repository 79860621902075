import React, {} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './style.css'

const categoryBanner = ({banner_title,banner_image,short_description2,categoryImgPath}) => {
    return(
        <div className="banner">
            <div className="container">
                <div className="row banner-inner">
                    <div className="offset-lg-1 col-lg-5 col-md-6 banner-left">
                        <h1>{banner_title}</h1>
                        <p>{short_description2}</p>
                    </div>
                    <div className="col-lg-4 col-md-5 offset-lg-2 offset-md-1 banner-right">
                        <figure className="lazy-banner-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                            <LazyLoadImage src={`${categoryImgPath}${banner_image}`} effect="blur" alt={banner_title}/>
                        </figure> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default categoryBanner;