import React, { } from 'react';
import { useDispatch } from "react-redux";
import {checkLogin} from "../../Action/actions";
import './style.css'

const MonthlyPrice = ({userStatus,amount,caption,website_url}) => {
    //Sign IN
    const dispatch = useDispatch();
    const OpenSignInPopup = () => {
        dispatch(checkLogin(true))
    }
    return(
        <>
        {/* {website_url && */}
        <div className="monthly-price">
            <strong>{amount}</strong>
            <span>{caption}</span>
            <div className="text-center">
                <a href={website_url} className={`button`} target="_blank" rel="noreferrer">Get Deal</a>
            </div>
        </div>
        </>
    )
}

export default MonthlyPrice;