import React, {} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './style.css';

const BlogBanner = ({banner}) => {
  return (
    <div className="banner">
      <div className="container">
          <div className="row banner-inner">
              <div className="offset-lg-1 col-lg-5 col-md-6 banner-left">
                <h1>{banner && banner.banner_title}</h1>
                <p>{banner && banner.banner_description}</p>
              </div>
              <div className="col-lg-4 col-md-5 offset-lg-2 offset-md-1 banner-right">
              <figure className="lazy-banner-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                <LazyLoadImage effect="blur" src={`${banner && banner.bannerUrl}${banner && banner.banner_image}`} alt={banner && banner.banner_title}/>
              </figure> 
              </div>
          </div>
      </div>
    </div>
  );
};

export default BlogBanner;
