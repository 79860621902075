import React, { useState,useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Slider from 'react-slick';
import { getTestimonial } from "../../utility/apiService";
import "./style.css";
const settings = {
  rows: 2,
  slidesPerRow: 2,
  infinite: true,
  speed: 800,
  dots: true,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 950,
      settings: {
        rows: 1,
        slidesPerRow: 1,
      }
    }
  ]
};
const Testimonials = () => {
 //Api
 const [testimonialItem, setTestimonialItem] = useState([]);
 const [categoryImgPath, setCategoryImgPath] = useState([]);
 useEffect(() => {
    getTestimonial()
   .then((res) => {
    setTestimonialItem(res.data.data)
    setCategoryImgPath(res.data.metadata.imageUrl)
   })
   .catch((error) => {
     console.error(error);
   })
 }, [])
  return (
    <section className="testimonial-outer">
      <div className="container">
          <Slider {...settings} className="testimonial-slider">
          {testimonialItem && testimonialItem.map((item,idx) => (
            <div className="item" key={idx}>
              <div className="testimonial-box">
                <div className="img">
                  <LazyLoadImage alt={item.name} effect="blur" src={`${categoryImgPath}${item.image_name}`} />
                </div>
                <div className="testimonial-text">
                  <p>{item.description}</p>
                  <strong>{item.name}</strong>
                  <span>{item.location}</span>
                </div>
              </div>
            </div>
            ))}
          </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
