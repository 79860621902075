import React from 'react';
import { Link } from 'react-router-dom';
import './style.css'

const relatedArticles = ({blogs,id}) => {
    return(
        <>
        {blogs && blogs.data && blogs.data.length > 0 ?
        <div className="related-articles">
            <h3>Related articles</h3>
            <ul className="related-article-list">
            {/* //{blogs && blogs.data.map((item,idx) => ( */}
            {blogs && blogs.data && blogs.data.length && blogs.data.slice(0,3).map((item,idx) => (
                <li key={idx}>
                    <Link to={`/blog/${item.slug}`}>
                    <div className="img">
                        <img src={`${blogs && blogs.imageUrl}${item.image_name}`} alt={item.title}/>
                    </div>
                    <div className="text">
                        <p>
                        {item.title}
                        </p>
                    </div>
                    </Link>
                </li> 
                ))}
            </ul>  
            <Link to={{pathname:'/blog', state:{category_id:id}}} className="green-link">See all articles</Link>
        </div>:null}
        </>
    )
}

export default relatedArticles;