import React, { useState,useEffect } from 'react';
import { postContact } from "../../utility/apiService";
import useLoader from "../../hook/useLoader";
import {toast } from 'react-toastify';
import './style.css'

const ContactForm = () => {
  /*handleSubmit*/
  const [loader,showLoader, hideLoader] = useLoader();
  const [inputVal, setInputVal] = useState({
    name:'',
    email:'',
    message:'',
  });
  const handleInput = (e) =>{
    setInputVal({
      ...inputVal,
      [e.target.name]:e.target.value
    })
  }
  const [isErrorEmpty, setErrorEmpty] = useState({
    nameError : false,
    emailError : false,
    messageError : false,
    validEmailError : false
  });
  const [checkValidation, setCheckValidation] = useState(false)
  const handleSubmit=(e)=>{
    e.preventDefault()
    setErrorEmpty({
      nameError : false,
      emailError : false,
      messageError : false,
      validEmailError : false
    });
    handleValidation()
    setCheckValidation(true)
  } 
  useEffect(() => {
    const payload = {
      name: inputVal.name,
      email: inputVal.email,
      message: inputVal.message,
      device_id: "12345"
    }
    if(checkValidation && !isErrorEmpty.nameError && !isErrorEmpty.emailError && !isErrorEmpty.messageError && !isErrorEmpty.validEmailError){
      showLoader();
      postContact(payload)
      .then((res) => {
        if(res.data.code === 200) {
          toast.success(res.data.message)
          setInputVal({
            name:'',
            email:'',
            message:'',
          })
        }
        if(res.data.code === 409) {
          toast.error(res.data.message)
        }
        hideLoader()
      })
      .catch((error) => {
        hideLoader()
        console.error(error);
      })
    }
    setCheckValidation(false)
 },[checkValidation])
 
 const handleValidation=()=>{
    if(inputVal.name ===''){  
      setErrorEmpty(prevError => {
        return { 
          ...prevError, 
          nameError: true
        }
      })
    }
    if(inputVal.email ===''){  
      setErrorEmpty(prevError => {
        return { 
          ...prevError, 
          emailError: true
        }
      })
    }else{
      let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!regEmail.test(inputVal.email)){
        setErrorEmpty(prevError => {
          return { 
            ...prevError, 
            validEmailError: true
          }
        })
      }
    }
    if(inputVal.message ===''){  
      setErrorEmpty(prevError => {
        return { 
          ...prevError, 
          messageError: true
        }
      })
    }
  }
    return(
      <>
      {loader}
        <div className="contact-outer section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="head text-left">
                            <h3 className="heading1">Contact Us</h3>
                            <p>At The Comparison Company, we want to positively impact you and your family by providing you with the information you need to be an informed consumer. We realize, though, that a website can only do so much, and you may have some questions or want to speak with a human. When that's the case, please complete the form below and we will get back to you as soon as possible.</p>
                        </div>
                    </div>
                </div>
                <div className="row pt-4">
                    <div className="col-lg-8 col-md-8">
                        <div className="contact-map">
                            <iframe title="TCC" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26784.995769913316!2d-96.840852!3d32.947723!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c26d1fc0ec853%3A0x84c407538a4e197!2s14681%20Midway%20Rd%20Suite%20200%2C%20Addison%2C%20TX%2075001!5e0!3m2!1sen!2sus!4v1628240741378!5m2!1sen!2sus" width="100%" height="487" border="0" loading="lazy"></iframe>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <form className="contact-form" onSubmit={handleSubmit}>
                            <h3>Let's Chat</h3>
                            <div className="form-group mb-3">
                                <label>Name <span className="error-star">*</span></label>
                                <input type="text" value={inputVal.name} name="name" className="form-control" onChange={(e)=>handleInput(e)}/>
                                {isErrorEmpty.nameError &&
                                <p className="error">Please Enter Name</p>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Email <span className="error-star">*</span></label>
                                <input type="text" value={inputVal.email} name="email" className="form-control" onChange={(e)=>handleInput(e)}/>
                                {isErrorEmpty.emailError &&
                                <p className="error">Please Enter Email</p>}
                                {isErrorEmpty.validEmailError &&
                                <p className="error">Please Enter Valid Email</p>}
                            </div>
                            <div className="form-group mb-3">
                                <label>Message <span className="error-star">*</span></label>
                                <textarea name="message" value={inputVal.message} className="form-control" onChange={(e)=>handleInput(e)}></textarea>
                                {isErrorEmpty.messageError &&
                                <p className="error">Please Write Message</p>}
                            </div>
                            <div className="btn-outer">
                                <button type="submit" className="button green">Send</button>
                            </div>
                            <p className="pt-1 text-center"><small>We won't use your email for anything other than to reply to you</small></p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}

export default ContactForm;