import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './style.css';

const AboutContent = () => {
  return (
    <div className="section about-content">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-8 offset-lg-1">
            <h2>The Comparison Company</h2>
            <p>The Comparison Company began with a simple thought. The name of our company represents that very idea; that is, an approach to provide comparisons amongst various services, such as banking, insurance, loans, and many more.</p>
            <p>We assessed several factors and found that people often struggle with finding the services that would benefit them the most. It is no surprise given the vast size of the market presently, leading to an intense competition that makes it tough to filter out the service that would work in their favor.</p>
            <p>So, with a vision to make the lives of our customers convenient, we help them make an informed decision about any service they require. Furthermore, we take it upon ourselves to ensure that our customers do not face any difficulty using our services. They can make as many comparisons as they want from the comfort of their home, at any time they feel like it!</p>
            <p>With our help, the customers do not have to spend hours finding and comparing services that would help them. Spend time with your friends and family instead of searching the market for that perfect deal. Be carefree about the same since we are here to do the difficult part of the job for you.</p>
          </div>
          <div className="col-lg-3 col-md-4 offset-lg-1 about-content-img">
          <LazyLoadImage src="../images/about.png" alt="About Img"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
