import React, {} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import './style.css'

const companyBanner = ({banner_title,banner_image,banner_description,categoryImgPath}) => {
    return(
        <div className="banner">
            <div className="container">
                <div className="row banner-inner">
                    <div className="offset-lg-1 col-lg-5 col-md-6 banner-left">
                        <h1>{banner_title}</h1>
                        <p>{banner_description}</p>
                    </div>
                    <div className="col-lg-4 col-md-5 offset-lg-2 offset-md-1 banner-right">
                        <figure className="lazy-banner-img" style={{backgroundImage: `url('../images/overlay-bg.jpg')`}}>
                            <LazyLoadImage effect="blur" src={`${categoryImgPath}${banner_image}`} alt={banner_title}/>
                        </figure> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default companyBanner;