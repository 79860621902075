import React, { useState,useEffect } from 'react';
import { useDispatch } from "react-redux";
import { FaSearch,FaMapMarkerAlt } from 'react-icons/fa';
import { getCityCode,getZipCode } from "../../utility/apiService";
import {checkLogin} from "../../Action/actions";
import { isEmpty } from "lodash";
import "./style.css";
const CitySearch = ({recZipCode}) => {
  //Sign IN
  const dispatch = useDispatch();
  const OpenSignInPopup = () => {
      dispatch(checkLogin(true))
  }
  const [userStatus, setUserStatus] = useState(true);
  useEffect(() => {
    const userInfoFromLS = localStorage.getItem("user_info");
    const userInfo = JSON.parse(userInfoFromLS);
    if (userInfo) {
      setUserStatus(false)
    }
  }, [])
  //Api
  const [cityName, setCityName] = useState([]);
  const [zipPin, setZipPin] = useState([]);
  useEffect(() => {
    if(localStorage.getItem('city_name') === null || localStorage.getItem('city_name') === undefined){
      getCityCode()
      .then((res) => {
        setCityName(res.data.city)
        setZipPin(res.data.postal)
        passZip(res.data.city)
        localStorage.setItem('city_name',res.data.city)
        localStorage.setItem('zip_code',res.data.postal)
      })
      .catch((error) => {
        console.error(error);
      })  
    }else{
      const city_nameS = localStorage.getItem('city_name')
      setCityName(city_nameS)
      passZip(city_nameS)
    }
  }, [])
  //const {city, countryCode} = cityName;
  const [zipCode, setZipCode] = useState('');
  const [inputZipcode, setInputZipcode] = useState('');
  const handleZipcode = (e) =>{
    e.preventDefault()
    setInputZipcode(e.target.value)
  }
  const [isError, setError] = useState(false);
  const [isErrorEmpty, setErrorEmpty] = useState(false);
  const handleSubmit=(e)=>{
    setError(false)
    setErrorEmpty(false)
    e.preventDefault()
    if(inputZipcode){
      getZipCode(inputZipcode)
      .then((res) => {
          if(isEmpty(res.data.city)){
            setError(true)
          }
          setZipCode(res.data.city.toLowerCase())
          localStorage.setItem('city_name',res.data.city.toLowerCase())
          passZip(res.data.city.toLowerCase())
          localStorage.setItem('zip_code',inputZipcode)
          setZipPin(inputZipcode)
          setInputZipcode('')
      })
      .catch((error) => {
        console.error(error);
      })
    }else{
      setErrorEmpty(true)
    }
  }  

  //passZip
  const passZip = (zipCodeP,cityP) =>{
    !!recZipCode && recZipCode(zipCodeP,cityP)
  } 
  const zipNcode = localStorage.getItem('zip_code')
  return (
    <div className="city-box">
      <p className="city-location">{zipCode ? zipCode : cityName}</p>
      <div className="input-outer">
        <form  onSubmit={(e)=>handleSubmit(e)}>
        <div className="pincode">{zipNcode ? zipNcode: zipPin}</div>
        <input type="text" value={inputZipcode} className="form-control" onChange={(e)=>handleZipcode(e)} placeholder="Use your zip code to find best deals"/>
        <FaMapMarkerAlt className="location-icon"/>
        <button type="submit" className="form-control submit"><FaSearch/></button>
        {isError &&
          <p className="error">Please Enter Valid Pincode</p>
        } 
        {isErrorEmpty &&
          <p className="error">Please Enter Pincode</p>
        }
        </form>
      </div>
      {userStatus &&
      <p className="already-login">Already a member? <button className="login" onClick={OpenSignInPopup}>Log In</button></p>}
  </div>
  );
};

export default CitySearch;
