import React, {} from 'react';
import './style.css';

const ResultNotFound = () => {
  return (
    <div className="result-not-found">
      <h3>Data Not Found</h3>
    </div>
  );
};

export default ResultNotFound;
