import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Link, useLocation} from 'react-router-dom';
import ResultNotFound from "../../component/ResultNotFound";
import {checkLogin} from "../../Action/actions";
import './style.css'
const CompareTable = ({deals,resultImgPath, vendors}) => {
    const location = useLocation() 
    const [toggle,setToggle] = useState(false)
    const [userStatus, setUserStatus] = useState(true);
    useEffect(() => {
        const userInfoFromLS = localStorage.getItem("user_info");
        const userInfo = JSON.parse(userInfoFromLS);
        if (userInfo) {
          setUserStatus(false)
        }
        setToggle(false)
      }, [location.pathname])
    const toolTipCheck =(str)=>{
        return str.indexOf("||") > -1
    }
    const utfCheck = str => {
        if(str.indexOf("|274E|") > -1) {
            return <div className="utf-no" style={{backgroundImage: `url('../images/utf-no.svg')`}}></div>
        }
        else if(str.indexOf("|274F|") > -1) {
            return <div className="utf-yes" style={{backgroundImage: `url('../images/utf-yes.svg')`}}></div>
        }
        else if(str.indexOf("|274G|") > -1) {
            return <div className="utf-notapplicable" style={{backgroundImage: `url('../images/utf-notapplicable.svg')`}}></div>
        }
        else {
            return str;
        }
    }
    const toolTipList = (str) =>{
        let temp = str.split("||");
        return <ul>
            {temp.map((value, index) => {
                if(value) {
                    return <li key={index}>{value}</li>
                }
            })}
        </ul>
    }
    //Sign IN
    const dispatch = useDispatch();
    const OpenSignInPopup = () => {
      dispatch(checkLogin(true))
    }
    return(
    <>
    {deals && deals.length > 0 ? (
    <section className="compare-table-outer section pb-0">
      <div className="container">
       <div className="row">
        <div className="offset-lg-1 col-lg-10 col-md-12">
            <table className="w-100 compare-table">
                <thead></thead>
                <tbody>
            {deals && deals.map((item,idx) => (
             item.type === 'vendors' &&  
                <tr key={idx}>
                    <th></th>
                    {item.data && item.data.map((i,idx) => (
                    <th key={idx}>
                        <Link to={`/company/${i.slug}`}>
                            <img src={`${resultImgPath}${i.logo_name}`} alt={item.type} />
                        </Link>
                    </th>
                    ))}
                </tr>
                ))}
                {deals && deals.length && deals.map((item,idx) => (
                item.type === 'price' && 
                <tr key={idx}>
                    <td className="drak-gray">{item.heading}</td>
                    {item.data && item.data.map((i,idx) => (
                    <td key={idx}>
                    <div className="m-box">
                        {i.prev_amount &&
                            !userStatus &&
                            <small className="prev-amount">{i.prev_amount}</small>
                        }
                        <strong>{i.amount}</strong>
                        <span>{i.caption}</span>
                        <div className="rating-icons" style={{backgroundImage: `url('../images/rating-gray.png')`}}>
                            <figure style={{width: `${i.rating*20}%`,backgroundImage: `url('../images/rating-yellow.png')`}}></figure>
                        </div>
                    </div>
                    </td>
                    ))}
                </tr>
                ))}
                {deals && deals.length && deals.slice(2,5).map((item,idx) => (
                item.type === 'text' && 
                <tr key={idx}>
                    <td>{item.heading}</td>
                    {item.data && item.data.map((i,idx) => (
                    // <td key={idx}>{i.text}</td>
                    <td key={idx}>{toolTipCheck(i.text) === true ? <div className="tc-popover-wrap" style={{backgroundImage: `url('../images/info.svg')`}}><div className="tc-popover"><h6>{item.heading}</h6>{toolTipList(i.text)}</div></div> : utfCheck(i.text)}</td>
                    ))}
                </tr>
                ))}
                {window.screen.width >= 767 &&
                deals && deals.length > 5 &&
                <tr className="gray-bg">
                    <td colSpan="6">
                        <span className={toggle ? 'active' : ''} onClick={()=>setToggle(!toggle)}>
                            {!toggle ? 
                            <>More time to spare? Extra details here</>
                            :<>Collapse</>}
                        </span>
                    </td>
                </tr>}
                {deals && deals.length && deals.slice(5).map((item,idx) => (
                item.type === 'text' && 
                toggle === (window.screen.width >= 767) &&
                <tr key={idx}>
                    <td>{item.heading}</td>
                    {item.data && item.data.map((i,idx) => (
                    <td key={idx}>
                        {toolTipCheck(i.text) === true ? <div className="tc-popover-wrap" style={{backgroundImage: `url('../images/info.svg')`}}><div className="tc-popover"><h6>{item.heading}</h6>{toolTipList(i.text)}</div></div> : utfCheck(i.text)}
                    </td>
                    ))}
                </tr>
                ))}
                {deals && deals.map((item,idx) => (
                item.type === 'vendors' && 
                    <tr key={idx}>
                        <td></td>
                        {item.data && item.data.map((i,idx) => (
                            <td key={idx}>
                                <a href={vendors[idx].website_url} target="_blank" className={`button`} rel="noreferrer">Get Deal</a>
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
        </div>
      </div>
    </section> ) : (<ResultNotFound />)}
    </>  
    )
}
export default CompareTable;