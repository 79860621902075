import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { getCommonData } from "../../utility/apiService";
import "./style.css";
const CompareAndSave = () => {
  //Api
  const [menuItem, setMenuItem] = useState([]);
  const [categoryImgPath, setCategoryImgPath] = useState([]);
  useEffect(() => {
    getCommonData()
    .then((res) => {
      setMenuItem(res.data.data.categories);
      setCategoryImgPath(res.data.metadata.imageUrl)
    })
    .catch((error) => {
      console.error(error);
    })
  }, [])
  return (
    <section className="compare-outer">
      <div className="container">
        <div className="row">
          <div className="offset-lg-3 col-lg-6 col-md-12">
            <h3 className="heading1 mb-0">Shop, Compare and Save </h3>
            <p className="heading2">on the best services and utilities </p>
          </div>
        </div>
        <div className="row">
          <div className="offset-lg-2 col-lg-10 col-md-12">
            <ul className="compare-box">
            {menuItem && menuItem.map((item,idx) => (
              <li key={idx}>
                <Link to={'/'+item.slug}>
                <div className="img">
                  <img src={`${categoryImgPath}${item.icon_name}`} alt={item.name}/>
                </div>
                <span>{item.name}</span>
                </Link>
              </li>
            ))}
            </ul>
          </div>
        </div>
        {/* <div className="row">
          <div className="offset-lg-3 col-lg-6 col-md-12">
            <div className="head">
              <h4 className="heading2">Searching for the best rate is exhausting. We fixed that.</h4>
              <p>Each day our AI searches hundreds of providers to find the best rates on utilities and bills.
              Our algorithm works alongside our Team of real-life reviewers to pick the best products and prices.</p>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default CompareAndSave;
