const initialState = {
    isLogin : false
};

export default function commonReducer(state = initialState, action){
    switch (action.type) {
        case 'CHECK_LOGIN' : 
        return {...state,isLogin: action.payload}
        default : return {...state}
    }
}