import React,{useEffect} from 'react';
import AboutBanner from '../../component/AboutBanner';
import AboutContent from '../../component/AboutContent';
import CitySearch from "../../component/CitySearch";
import AboutBenifits from '../../component/AboutBenifits';
import AboutMission from '../../component/AboutMission';
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <>
      <AboutBanner/>
      {/* <section className="city-outer white-bg">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
            <CitySearch />
            </div>
          </div>
        </div>
      </section> */}
      <AboutContent/>
      <AboutBenifits/>
      <AboutMission/>
    </>
  );
};

export default AboutUs;
