import React, { useEffect,useState } from "react";
import {Helmet} from "react-helmet";
import { useLocation } from "react-router-dom";
import BlogDetailBanner from "../../component/BlogDetailBanner";
import BlogDetailList from "../../component/BlogDetailList";
import BlogCategories from "../../component/BlogCategories";
import Adds from "../../component/Adds";
import BlogPopularArticle from "../../component/BlogPopularArticle";
import BlogArchive from "../../component/BlogArchive";
import { getBlogPageData,getBlog } from "../../utility/apiService";
import { isEmpty } from "lodash";

const BlogDetal = () => {
//ApiBanner
const [blogDetailItemB, setBlogDetailItemB] = useState([]);
const location = useLocation() 
useEffect(() => {
let slug = location && location.pathname && location.pathname.split('/')[2]
getBlog(slug)
.then((res) => {
    setBlogDetailItemB(res.data.data)
    setCategoryImgPath(res.data.metadata.imageUrl)
})
.catch((error) => {
    console.error(error);
})
}, [location])

//For Blog Detail Side bars
const [blogDataItem, setBlogDataItem] = useState([]);
const [categoryImgPath, setCategoryImgPath] = useState([]);
useEffect(() => {
  getBlogPageData()
  .then((res) => {
    setBlogDataItem(res.data.data)
    setCategoryImgPath(res.data.metadata.imageUrl)
  })
  .catch((error) => {
    console.error(error);
  })
}, [])
const categoryFilter = () =>{
  return true
}
const archiveFilter=()=>{
  return true
}
const {category,archive,popular,ads} = blogDataItem;
const {meta_title,meta_description,meta_keywords,banner_title,banner_description,banner_image} = blogDetailItemB;
  return (
    <>
      <Helmet>
        <title>{meta_title && meta_title}</title>
        <meta name="description" content={meta_description && meta_description} />
        <meta name="keywords" content={meta_keywords && meta_keywords} />
      </Helmet>
      <BlogDetailBanner banner_title={banner_title} banner_image={banner_image} banner_description={banner_description} categoryImgPath={categoryImgPath}/>
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-8 col-md-8">
              <BlogDetailList />
            </div>
            <div className="col-lg-3 col-md-4">
            {(!isEmpty(category) || !isEmpty(popular) || !isEmpty(archive) || !isEmpty(ads)) &&
              <div className="right-side-bar">
                <BlogCategories categoryFilter={categoryFilter} category={category} />
                <BlogPopularArticle popular={popular}/>
                <BlogArchive archiveFilter={archiveFilter} archive={archive}/>
                <Adds ads={ads}/>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetal;
