import React, { useEffect,useState } from "react";
import {Helmet} from "react-helmet";
import { useLocation,useHistory } from "react-router-dom";
import ResultBanner from "../../component/ResultBanner";
import CitySearch from "../../component/CitySearch";
import CompareTable from "../../component/CompareTable";
import ComparisonTopHead from "../../component/ComparisonTopHead";
import ComparisonsList from "../../component/ComparisonsList";
import CompareAndSave from "../../component/CompareAndSave";
import RecentComparisons from "../../component/RecentComparisons";
import HowCompar from "../../component/HowCompar";
import Adds from "../../component/Adds";
import RelatedArticles from "../../component/RelatedArticles";
import { getCategories } from "../../utility/apiService";
import { isEmpty } from "lodash";

const Result = () => {
  //Api
  const [resultItem, setResultItem] = useState([]);
  const [categoryImgPath, setCategoryImgPath] = useState([]);
  const [resultImgPath, setResultImgPath] = useState([]);
  const [isLoding, setIsloading] = useState(false);
  const location = useLocation() 
  const history = useHistory()
  useEffect(() => {
    setIsloading(true) 
    let slug = location && location.pathname && location.pathname.split('/')[2]
    let zip_code = localStorage.getItem('zip_code')
    getCategories(slug,zip_code)
    .then((res) => {
      if(!!res.data){
        setIsloading(false) 
      }
      if(isEmpty(res.data.data)){
        history.push('/page-not-found')
      }
      setResultItem(res.data.data)
      setCategoryImgPath(res.data.metadata.imageUrl)
      setResultImgPath(res.data.metadata.vendorUrl)
    })
    .catch((error) => {
      console.error(error);
      setIsloading(false) 
    })
    window.scrollTo(0,0);
  }, [location])
  const {banner_title,banner_image,short_description1,meta_title,meta_description,meta_keywords,name,vendors,deals,ads,blogs,comparison_text,id,description2} = resultItem;
  return (
    <>
      {isLoding &&
        <div className="loader" style={{backgroundImage: `url('../images/loader.png')`}}></div>
      }
      <Helmet>
        <title>{meta_title && meta_title}</title>
        <meta name="description" content={meta_description && meta_description} />
        <meta name="keywords" content={meta_keywords && meta_keywords} />
      </Helmet>
      <ResultBanner name={name} banner_title={banner_title} banner_image={banner_image} categoryImgPath={categoryImgPath} short_description1={short_description1}/>
      {/* <section className="city-outer white-bg">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-md-12">
              <CitySearch />
            </div>
          </div>
        </div>
      </section> */}
      <CompareTable deals={deals} resultImgPath={resultImgPath} vendors={vendors}/>
      <ComparisonTopHead comparison_text={comparison_text}/>
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-8 col-md-8">
              <ComparisonsList vendors={vendors} resultImgPath={resultImgPath}/>
            </div>
            <div className="col-lg-3 col-md-4">
              {(!isEmpty(blogs && blogs.data) || !isEmpty(ads)) &&
              <div className="right-side-bar">
                <RelatedArticles categoryImgPath={categoryImgPath} blogs={blogs} id={id}/>
                <Adds ads={ads}/>
              </div>}
            </div>
          </div>
        </div>
      </div>
      <RecentComparisons/>
      <HowCompar description2={description2}/>
      <CompareAndSave/>
    </>
  );
};

export default Result;
