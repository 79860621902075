import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import { getCommonData, getVendors, getBlogPageData, getBlogs} from "../../utility/apiService";
import './style.css';

const SiteMapContent = () => {
  //Api 
  const [menuItem, setMenuItem] = useState([]);
  useEffect(() => {
      getCommonData()
      .then((res) => {
          setMenuItem(res.data.data.categories)
      })
      .catch((error) => {
          console.error(error)
      })
  }, [])
  //Api Company
  const [logo, setLogo] = useState([]);
  useEffect(() => {
    getVendors()
    .then((res) => {
      setLogo(res.data.data)
    })
    .catch((error) => {
      console.error(error);
    })
  }, [])
  // API Blog
  const [sitemapBlog, setSitemapBlog] = useState([]);
  useEffect(() => {
    getBlogPageData()
    .then((res) => {
      getBlogs()
      .then((resData) => {
        setSitemapBlog(siteMapBlogList(res.data.data.category, resData.data.data))
      })
      .catch((error) => {
          console.error(error)
      })
    })
    .catch((error) => {
        console.error(error)
    })
  }, [])
  const siteMapBlogList = (v1, v2) => {
    let arrayList = [];
    v1.map(elm => {
      v2.map(item => {
        const obj = {
          name: null,
          slug: null,
          data: []
        }
        if(elm.id === item.category_id) {
          if(arrayList.length) {
            arrayList.map(el => {
              if(el.name === elm.name && el.slug === elm.slug) {
                el.data.push(item)
              }
              else {
                obj.name = elm.name;
                obj.slug = elm.slug;
                obj.data.push(item)
                arrayList.push(obj)
              }
            })
          }
          else {
            obj.name = elm.name;
            obj.slug = elm.slug;
            obj.data.push(item)
            arrayList.push(obj)
          }
        }
      })
    })
    return arrayList;
  }
  return (
    <div className="section site-map">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-4">
            <h3>Products</h3>
            {menuItem && menuItem.map((item,idx) => (
              <div className="footer-menu-outer" key={idx}>
                <h4><Link to={'/'+item.slug}>{item.name}</Link></h4>
                <ul className="footer-menu">
                  {item.subcategory.map((i,idx) => (
                      <li key={idx}><Link to={'/'+item.slug+'/'+i.slug}>{i.name}</Link></li> 
                  ))}
                </ul>
              </div>
            ))}  
          </div>
          <div className="col-lg-3 col-sm-4">
            <h3>Blogs</h3>
            {sitemapBlog && sitemapBlog.map((item,idx) => (
              <div className="footer-menu-outer" key={idx}>
                <h4><Link to={'/'+item.slug}>{item.name}</Link></h4>
                <ul className="footer-menu">
                  {item.data.map((i,idx) => (
                      <li key={idx}><Link to={'/'+item.slug+'/'+i.slug}>{i.title}</Link></li> 
                  ))}
                </ul>
              </div>
            ))} 
          </div>
          <div className="col-lg-3 col-sm-4">
            <h3>Company</h3>
            <ul className="footer-menu">
            {logo && logo.map((item,idx) => (
              // item.ispopular === '1' &&
              <li key={idx}>
                <Link to={'/company/'+item.slug}>
                {item.name}
                </Link>
              </li>
              ))}
            </ul>          
          </div>
          <div className="col-lg-3 col-sm-4">
            <h3>Others</h3>
            <ul className="footer-menu">
              <li>
              <Link to='/about-us'>About Us</Link>
              </li>
              <li>
              <Link to='/contact-us'>Contact Us</Link>
              </li>
              <li>
              <Link to='/partners-contact-us'>Partner Contact Us</Link>
              </li>
              <li>
              <Link to='/privacy-policy'>Privacy Policy</Link>
              </li>
              <li>
              <Link to='/term-condition'>Terms And Conditions</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteMapContent;
